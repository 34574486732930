export const FETCH_START = 'FETCH_START';
export const FETCH_END = 'FETCH_END';
export const PROMOTION_DATA_SUCCESS = 'PROMOTION_DATA_SUCCESS';
export const PROMO_FINANCE_COST_DATA_SUCCESS =
  'PROMO_FINANCE_COST_DATA_SUCCESS';
export const PROMO_TERRITORY_DATA_SUCCESS = 'PROMO_TERRITORY_DATA_SUCCESS';
export const PROMO_TERRITORY_FORMAT_SUCCESS = 'PROMO_TERRITORY_FORMAT_SUCCESS';
export const PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS =
  'PROMO_TERRITORY_FORMAT_BY_ID_SUCCESS';
export const PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS =
  'PROMO_TERRITORY_FORMAT_FIELDS_SUCCESS';
export const PROMO_TERRITORY_UPLOAD_SUCCESS = 'PROMO_TERRITORY_UPLOAD_SUCCESS';
export const PROMOTION_SETUP_SUCCESS = 'PROMOTION_SETUP_SUCCESS';
export const PROMOTION_SETUP_BY_ID = 'PROMOTION_SETUP_BY_ID';
export const PROMOTION_MATERIAL_SUCCESS = 'PROMOTION_MATERIAL_SUCCESS';
export const PROMO_FINANCE_COST_DETAIL_SUCCESS =
  'PROMO_FINANCE_COST_DETAIL_SUCCESS';
export const FINANCE_COST_FORMAT_SUCCESS = 'FINANCE_COST_FORMAT_SUCCESS';
export const FINANCE_COST_FORMAT_BY_ID_SUCCESS =
  'FINANCE_COST_FORMAT_BY_ID_SUCCESS';
export const FINANCE_COST_FORMAT_FIELDS_SUCCESS =
  'FINANCE_COST_FORMAT_FIELDS_SUCCESS';
export const PROMO_FINANCE_COST_UPLOAD_SUCCESS =
  'PROMO_FINANCE_COST_UPLOAD_SUCCESS';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const POSTING_FAILED = 'POSTING_FAILED';
export const GLOBAL_SETUP_BY_ID = 'GLOBAL_SETUP_BY_ID';
export const GLOBAL_SETUP_SUCCESS = 'GLOBAL_SETUP_SUCCESS';
export const PROMO_MAP_SUCCESS = 'PROMO_MAP_SUCCESS';
export const PROMO_MAP_SINGLE_SUCCESS = 'PROMO_MAP_SINGLE_SUCCESS';
export const PROMO_MAPPING_FORMAT_SUCCESS = 'PROMO_MAPPING_FORMAT_SUCCESS';
export const PROMO_MAP_FORMAT_BY_ID = 'PROMO_MAP_FORMAT_BY_ID';
export const PROMO_MAP_FORMAT_FIELDS = 'PROMO_MAP_FORMAT_FIELDS';
export const PROMO_SIM_FAILURE = 'PROMO_SIM_FAILURE';
export const PROMO_TYPES = 'PROMO_TYPES';
export const PROMO_STICKERS = 'PROMO_STICKERS';
export const PROMO_STICKER_BY_ID = 'PROMO_STICKER_BY_ID';
export const MARKET_FILTERS = 'MARKET_FILTERS';
export const PROMO_FILTERS = 'PROMO_FILTERS';
export const MODEL_GROUP = 'MODEL_GROUP';
export const LOCATION_GROUP = 'LOCATION_GROUP';
export const MODEL_LIST_GROUP = 'MODEL_LIST_GROUP';
export const PROMO_CONFIG_UPLOAD_SUCCESS = 'PROMO_CONFIG_UPLOAD_SUCCESS';
export const PROMO_CONFIG_FORMAT_FIELDS_SUCCESS =
  'PROMO_CONFIG_FORMAT_FIELDS_SUCCESS';
export const PROMO_CONFIG_FORMAT_SUCCESS = 'PROMO_CONFIG_FORMAT_SUCCESS';
export const MATERIAL_PRICING_UPLOAD_SUCCESS =
  'MATERIAL_PRICING_UPLOAD_SUCCESS';
export const MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS =
  'MATERIAL_PRICING_FORMAT_FIELDS_SUCCESS';
export const MATERIAL_PRICING_FORMAT_SUCCESS =
  'MATERIAL_PRICING_FORMAT_SUCCESS';
export const MATERIAL_PRICING_DATA_SUCCESS = 'MATERIAL_PRICING_DATA_SUCCESS';
export const MATERIAL_PRICING_SINGLE_DATA_SUCCESS =
  'MATERIAL_PRICING_SINGLE_DATA_SUCCESS';
export const PROMO_CONFIG_SINGLE_DATA_SUCCESS =
  'PROMO_CONFIG_SINGLE_DATA_SUCCESS';
export const SCENARIO_LIST_DATA_SUCCESS = 'SCENARIO_LIST_DATA_SUCCESS';
export const SAND_BOX_DATA_SUCCESS = 'SAND_BOX_DATA_SUCCESS';
export const HISTORY_DATA_SUCCESS = 'HISTORY_DATA_SUCCESS';
export const PROMOTIONS_SEARCH_SUCCESS = 'PROMOTIONS_SEARCH_SUCCESS';
export const COSTING_SIMULATION_SUCCESS = 'COSTING_SIMULATION_SUCCESS';
export const COSTING_START = 'COSTING_START';
export const COSTING_END = 'COSTING_END';
export const PROMO_CONFIG_DATA_SUCCESS = 'PROMO_CONFIG_DATA_SUCCESS';
export const PREFILL_SCENARIO_SUCCESS = 'PREFILL_SCENARIO_SUCCESS';
export const SCENARIO_BY_ID = 'SCENARIO_BY_ID';
export const COVERAGE_MAPPING_UPLOAD_SUCCESS =
  'COVERAGE_MAPPING_UPLOAD_SUCCESS';
export const COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS =
  'COVERAGE_MAPPING_FORMAT_FIELDS_SUCCESS';
export const COVERAGE_MAPPING_FORMAT_SUCCESS =
  'COVERAGE_MAPPING_FORMAT_SUCCESS';
export const COVERAGE_MAPPING_DATA_SUCCESS = 'COVERAGE_MAPPING_DATA_SUCCESS';
export const COVERAGE_MAPPING_SINGLE_SUCCESS =
  'COVERAGE_MAPPING_SINGLE_SUCCESS';
export const STANDARD_FINANCE_UPLOAD_SUCCESS =
  'STANDARD_FINANCE_UPLOAD_SUCCESS';
export const STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS =
  'STANDARD_FINANCE_FORMAT_FIELDS_SUCCESS';
export const STANDARD_FINANCE_FORMAT_SUCCESS =
  'STANDARD_FINANCE_FORMAT_SUCCESS';
export const STANDARD_FINANCE_DATA_SUCCESS = 'STANDARD_FINANCE_DATA_SUCCESS';
export const STANDARD_FINANCE_SINGLE_DATA_SUCCESS =
  'STANDARD_FINANCE_SINGLE_DATA_SUCCESS';
export const PROMOTION_CURRENCY_UPLOAD_SUCCESS =
  'PROMOTION_CURRENCY_UPLOAD_SUCCESS';
export const PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS =
  'PROMOTION_CURRENCY_FORMAT_FIELDS_SUCCESS';
export const PROMOTION_CURRENCY_FORMAT_SUCCESS =
  'PROMOTION_CURRENCY_FORMAT_SUCCESS';
export const PROMOTION_CURRENCY_DATA_SUCCESS =
  'PROMOTION_CURRENCY_DATA_SUCCESS';
export const PROMOTION_CURRENCY_SINGLE_SUCCESS =
  'PROMOTION_CURRENCY_SINGLE_SUCCESS';
export const TERRITORY_ID_SUCCESS = 'TERRITORY_ID_SUCCESS';
export const SAVED_SCENARIO = 'SAVED_SCENARIO';
export const SALES_FORECAST_SINGLE_DATA_SUCCESS =
  ' SALES_FORECAST_SINGLE_DATA_SUCCESS';
export const SCENARIO_MARKETS = 'SCENARIO_MARKETS';
export const PREVIOUS_SCENARIO = 'PREVIOUS_SCENARIO';
export const REVIEW_SCENARIO = 'REVIEW_SCENARIO';
export const CURRENT_SCENARIO = 'CURRENT_SCENARIO';
export const TR_APPROVAL_DATA_SUCCESS = 'TR_APPROVAL_DATA_SUCCESS';
export const TR_APPROVAL_REVIEW_DATA_SUCCESS =
  'TR_APPROVAL_REVIEW_DATA_SUCCESS';
export const TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS =
  'TR_APPROVAL_REVIEW_COMPLETED_DATA_SUCCESS';
export const MARKET_START = 'MARKET_START';
export const MARKET_END = 'MARKET_END';
export const CUSTOM_GROUP_ID = 'CUSTOM_GROUP_ID';
export const CUSTOM_GROUPS = 'CUSTOM_GROUPS';
export const CUSTOM_GROUP_LIST = 'CUSTOM_GROUP_LIST';
export const GROUPED_CUSTOM_GROUP = 'GROUPED_CUSTOM_GROUP';
export const PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS =
  'PROMOTION_COSTING_COMPARISON_UPLOAD_SUCCESS';
export const PROMO_COSTING_DROPDOWN_DATA_SUCCESS =
  'PROMO_COSTING_DROPDOWN_DATA_SUCCESS';
export const ARCHIVE = 'ARCHIVE';
export const STICKER_ARCHIVE = 'STICKER_ARCHIVE';
export const PROMO_COSTING_TABLE_DATA_SUCCESS =
  'PROMO_COSTING_TABLE_DATA_SUCCESS';
export const LOCATION_FILTER = 'LOCATION_FILTER';
export const SCENARIO_DATA = 'SCENARIO_DATA';
export const GRID_DATA = 'GRID_DATA';
export const USER_ACCESS_DATA = 'USER_ACCESS_DATA';
export const CURRENT_MODEL_YEAR = 'CURRENT_MODEL_YEAR';
export const CLEAR_DATA = 'CLEAR_DATA';
export const PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS =
  'PROMO_FINANCE_COSTING_DIALOG_DATA_SUCCESS';
export const PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS =
  'PROMO_PROMOTIONAL_OFFERS_DATA_SUCCESS';
export const PROMO_REGULAR_OFFERS_DATA_SUCCESS =
  'PROMO_REGULAR_OFFERS_DATA_SUCCESS';
export const PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS =
  'PROMO_REGULAR_OFFERS_UPLOAD_SUCCESS';
export const PROMO_REGULAR_OFFERS_FORMAT_SUCCESS =
  'PROMO_REGULAR_OFFERS_FORMAT_SUCCESS';
export const PROMO_REGULAR_OFFERS_FIELDS_SUCCESS =
  'PROMO_REGULAR_OFFERS_FIELDS_SUCCESS';
export const PROMO_REGULAR_FINANCING_DATA_SUCCESS =
  'PROMO_REGULAR_FINANCING_DATA_SUCCESS';
export const DOWNLOADING = 'DOWNLOADING';
export const VALID_MARKETS = 'VALID_MARKETS';
export const PROMO_COMMENT_DIALOG_DATA_SUCCESS =
  'PROMO_COMMENT_DIALOG_DATA_SUCCESS';
