import secureLocalStorage from 'react-secure-storage';
import {
  CUSTOMER_MASTER_SUCCESS,
  CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS,
  CUSTOMER_MASTER_XREF_SUCCESS,
  CUSTOMER_MASTER_XREF_DETAIL_SUCCESS,
  CUSTOMER_MASTER_XREF_FORMAT_SUCCESS,
  CUSTOMER_MASTER_XREF_BY_ID_SUCCESS,
  CUSTOMER_MASTER_XREF_FIELDS_SUCCESS,
  CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS,
  MATERIAL_MASTER_SUCCESS,
  MATERIAL_MASTER_VIEW_DETAIL_SUCCESS,
  MATERIAL_MASTER_XREF_SUCCESS,
  MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS,
  MATERIAL_MASTER_XREF_FORMAT_SUCCESS,
  MATERIAL_MASTER_XREF_BY_ID_SUCCESS,
  MATERIAL_MASTER_XREF_FIELDS_SUCCESS,
  MATERIAL_MASTER_XREF_UPLOAD_SUCCESS,
  SUPPLIER_MASTER_SUCCESS,
  SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS,
  IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS,
  IMA_CONFIG_CUSTOMER_MASTER_SUCCESS,
  IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS,
  MEMBERSHIP_SUCCESS,
  MASTER_REPORT_SUCCESS,
  MEMBERSHIP_DETAIL_SUCCESS,
  MEMBERSHIP_FORMAT_SUCCESS,
  MEMBERSHIP_FORMAT_BY_ID_SUCCESS,
  MEMBERSHIP_FORMAT_FIELDS_SUCCESS,
  MEMBERSHIP_UPLOAD_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS,
  BOM_SUCCESS,
  BOM_DETAIL_SUCCESS,
  BOM_FORMAT_SUCCESS,
  BOM_FORMAT_BY_ID_SUCCESS,
  BOM_FORMAT_FIELDS_SUCCESS,
  BOM_UPLOAD_SUCCESS,
  EMPLOYEE_MASTER_SUCCESS,
  EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS,
  INTEGRATION_SETUP_SUCCESS,
  TRACK_ACTIVITY_SUCCESS,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_FAIL,
  FILTER_BY_ID_LIST_SUCCESS,
  TERRITORY_SUCCESS,
  FETCH_START,
  FETCH_END,
  APPROVAL_DESIGNER_GET_SUCCESS,
  APPROVAL_DESIGNER_DETAIL_SUCCESS,
  CONTRACT_DEFAULT_DESIGNER_SUCCESS,
  CONTRACT_DEFAULT_DETAIL_SUCCESS,
  PAYMENT_DESIGNER_GET_SUCCESS,
  ACCRUAL_DESIGNER_GET_SUCCESS,
  ACCRUAL_DESIGNER_SINGLE_SUCCESS,
  PAYMENT_DESIGNER_SINGLE_SUCCESS,
  SALES_BASIS_ALL,
  SALES_BASIS_SINGLE,
  BATCH_JOB_ALL,
  BATCH_JOB_DETAIL,
  PRICING_BATCH_JOB_ALL,
  PRICING_BATCH_JOB_DETAIL,
  EXCEPTION_MANAGEMENT_ALL,
  EXCEPTION_MANAGEMENT_SINGLE,
  EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS,
  EXCEPTION_MANAGEMENT_TIER_EVALS,
  WORKFLOW_ANCHORS,
  BATCH_JOB_STATUS,
  BATCH_JOB_STATUS_SECOND_PAGE,
  BATCH_JOB_STATUS_SINGLE_DATA,
  PRODUCT_GROUP_SUCCESS,
  PRODUCT_GROUP_SINGLE_SUCCESS,
  PRODUCT_GROUP_FORMAT_SUCCESS,
  PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS,
  PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS,
  PRODUCT_GROUP_UPLOAD_SUCCESS,
  GENERAL_UOM,
  MATERIAL_UOM_SUCCESS,
  CONVERSION_SUCCESS,
  TERRITORY_LIST_SUCCESS,
  TERRITORY_SINGLE_SUCCESS,
  CONTRACT_XREF_SUCCESS,
  CONTRACT_XREF_DETAIL_SUCCESS,
  IMA_CONFIG_MATERIAL_MASTER_SUCCESS,
  IMA_CONFIG_SUPPLIER_SUCCESS,
  SUPPLIER_MASTER_DROPDOWN_SUCCESS,
  INTEGRATION_MAPPING_SUCCESS,
  INTEGRATION_MAPPING_BY_ID,
  INTEGRATION_MAPPING_FIELDS,
  MAPPING_CREATE_SUCCESS,
  INTEGRATION_LOAD_FREQUENCY,
  INTEGRATION_ACTIVITY,
  INTEGRATION_DATA_SOURCE,
  IM_HISTORY_SUCCESS,
  BATCH_INTEGRATION_JOB_ALL,
  BATCH_INTEGRATION_JOB_STATUS,
  FECTCH_INTEGRATION_SETUP_SUCCESS,
  COT_XREF_SUCCESS,
  COT_XREF_FORMAT_SUCCESS,
  COT_XREF_UPLOAD_SUCCESS,
  COT_XREF_FIELDS_SUCCESS,
  COT_XREF_BY_ID_SUCCESS,
  COT_XREF_VIEW_DETAIL_SUCCESS,
  MEMBERSHIP_HIERARCHY_SUCCESS,
  MEMBERSHIP_CONTRACT_XREF_SUCCESS,
  MEMBERSHIP_CONTRACT_XREF_BY_ID,
  MEMBERSHIP_CONTRACT_GROUP_SUCCESS,
  MEMBERSHIP_CONTRACT_GROUP_BY_ID,
  SALES_BUNDLE_SUCCESS,
  GPO_ADDRESS_SEARCH_SUCCESS,
  TIER_ACTI_ADDRESS_SEARCH_SUCCESS,
  MASS_MEMBERSHIP_UPDATE,
  MASS_MEBERSHIP_UPDATE_ACTION,
  WORK_FLOW_ASSIGNMENTS_SUCCESS,
  WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS,
  UPLOAD_INTEGRATION_FILE,
} from './ActionTypes';
import axios from '../../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../../library/constants';
import { history } from '../../../../components/Helpers';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};
//customer master user
export const addCustomerMasterUser = (data, page, reconcileData, appId) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCustomerMasterUserApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (page != 'incomingClaims') {
          history.push('/customer-master');
          toast.success('Successfully added.');
        } else {
          toast.success('Successfully created cuustomer..');
          dispatch(reConcile(reconcileData, appId));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

export const reConcile = (data, claimNumber, reconcile) => {
  return (dispatch) => {
    reConcileAPI(data)
      .then((response) => {
        toast.success('Successfully reconciled');
        // if (reconcile === 'reconcile') {
        //     dispatch(getIncomingClaims());
        // } else {
        //     dispatch(editIncomingClaim(claimNumber));
        // }
      })
      .catch((error) => {
        //toast.error('Error in updating data. Please try again.')
      });
  };
};

const reConcileAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('update-claims', data, { headers: headers });
  return result;
};

const addCustomerMasterUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('customer-data', data, { headers: headers });
  return result;
};

//get list customer master
export const getCustomerMasterData = (pagination, limit, keyword, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCustomerMasterDataApi(pagination, limit, keyword, data)
      .then((response) => {
        if (keyword === 'search') {
          dispatch(fetchEnd());
          dispatch(getCustomerMastrDataSuccess(response.data.response));
          history.push('/customer-master');
        } else {
          dispatch(fetchEnd());
          dispatch(getCustomerMastrDataSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getCustomerMasterDataApi = (pagination, limit, keyword, data) => {
  if (keyword === 'search') {
    for (var propName in data) {
      if (
        data[propName] === '' ||
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName].length === 0 ||
        data[propName] ===
        '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
        data[propName] === '1970-01-01T00:00:00.000Z' ||
        data[propName] === ','
      ) {
        delete data[propName];
      }
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  // if (keyword === 'search') {
  //   result = axios.get("customer-data?page=" + pagination + "&&limit=" + 0, { params: data }, { headers: headers });
  // }
  if (keyword === 'search') {
    result = axios.post(
      'master-data-search?page=' +
      pagination +
      '&&limit=' +
      0 +
      '&&table_name=customer_master',
      data,
      { headers: headers },
    );
  } else {
    if (pagination) {
      result = axios.get(
        'customer-data?page=' + pagination + '&&limit=' + limit,
        {
          headers: headers,
        },
      );
    } else {
      result = axios.get('customer-data?limit=' + limit, { headers: headers });
    }
  }
  return result;
};

export const getCustomerMastrDataSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_SUCCESS,
    data,
  };
};

//delete customer master
export const deleteCustomerMasterData = (id, page) => {
  return (dispatch) => {
    deleteCustomerMasterDataAPI(id, page)
      .then((response) => {
        toast.success(response.data.response);
        if (page === 'CustomerXMaster') dispatch(getCustomerMasterXRefData());
        if (page === 'MaterialMaster') {
          dispatch(
            getMaterialMasterData(
              1,
              0,
              'search',
              JSON.parse(sessionStorage.getItem('search-data')),
            ),
          );
          dispatch(
            getMaterialMasterData(
              1,
              0,
              'search',
              JSON.parse(sessionStorage.getItem('search-data')),
            ),
          );
          history.push('/material-master');
        }
        if (page === 'SupplierMaster') {
          dispatch(
            getSupplierMasterData(
              1,
              0,
              'search',
              JSON.parse(sessionStorage.getItem('search-data')),
            ),
          );
          history.push('/supplier-master');
        }
        if (page === 'Membership') dispatch(getMembershipData(1, 10));
        if (page === 'SalesBundle') dispatch(getBomData());
        if (page === 'EmployeeMaster') dispatch(getEmployeeData(1, 10));
        if (page === 'CustomerMaster') {
          dispatch(
            getCustomerMasterData(
              1,
              0,
              'search',
              JSON.parse(sessionStorage.getItem('search-data')),
            ),
          );
          dispatch(
            getCustomerMasterData(
              1,
              0,
              'search',
              JSON.parse(sessionStorage.getItem('search-data')),
            ),
          );
          history.push('/customer-master');
        }
        if (page === 'MaterialXMaster')
          dispatch(getMaterialMasterXRefData(1, 10));
        if (page === 'ContractXREF') dispatch(getContractXRefData());
      })
      .catch((error) => {
        //toast.error('Something went wrong.');
      });
  };
};

const deleteCustomerMasterDataAPI = (id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'CustomerXMaster')
    result = axios.delete('xref-customer-data/' + id, { headers: headers });
  else if (page === 'MaterialMaster') {
    result = axios.delete('material-master/' + id, { headers: headers });
  } else if (page === 'SupplierMaster')
    result = axios.delete('supplier-master/' + id, { headers: headers });
  else if (page === 'Membership')
    result = axios.delete('membership/' + id, { headers: headers });
  else if (page === 'SalesBundle')
    result = axios.delete('bom/' + id, { headers: headers });
  else if (page === 'EmployeeMaster')
    result = axios.delete('employee/' + id, { headers: headers });
  else if (page === 'MaterialXMaster')
    result = axios.delete('xref-material-master/' + id, { headers: headers });
  else if (page === 'ContractXREF')
    result = axios.delete('xref-contract/' + id, { headers: headers });
  else result = axios.delete('customer-data/' + id, { headers: headers });
  return result;
};

//customer master detail view
export const getCustomerMasterViewDetails = (id, mode) => {
  return (dispatch) => {
    getCustomerMasterViewDetailsApi(id).then((response) => {
      if (mode != 'edit') history.push('/customer-master/view-customer/' + id);
      dispatch(customerMasterViewDetailsSuccess(response.data.response));
    });
  };
};
const getCustomerMasterViewDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('customer-data/' + id, { headers: headers });
  return result;
};

export const customerMasterViewDetailsSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_VIEW_DETAIL_SUCCESS,
    data,
  };
};

//edit customer data
export const editCustomerMasterUser = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editCustomerMasterUserApi(data, id)
      .then((response) => {
        dispatch(
          getCustomerMasterData(
            1,
            0,
            'search',
            JSON.parse(sessionStorage.getItem('search-data')),
          ),
        );
        history.push('/customer-master');
        toast.success('Updated successfully');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editCustomerMasterUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('customer-data/' + id, data, { headers: headers });
  return result;
};

//customer master x ref

//add customer master xref
export const addCustomerMasterXRefUser = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCustomerMasterXRefUserApi(data)
      .then((response) => {
        toast.success('Successfully added.');
        history.push('/customer-x-master');
        dispatch(getCustomerMasterXRefData());
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addCustomerMasterXRefUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('xref-customer-data', data, { headers: headers });
  return result;
};

//edit customer master xref
export const editCustomerMasterXRefUser = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editCustomerMasterXRefUserApi(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('Successfully updated.');
        history.push('/customer-x-master');
        dispatch(getCustomerMasterXRefData());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editCustomerMasterXRefUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('xref-customer-data/' + id, data, { headers: headers });
  } else {
    result = axios.post('xref-customer-data', data, { headers: headers });
  }
  return result;
};

//delete customer master xref
export const deleteCustomerMasterXRefUser = (id) => {
  return (dispatch) => {
    deleteCustomerMasterXRefUserApi(id)
      .then((response) => {
        toast.success('Successfully deleted.');
      })
      .catch((error) => { });
  };
};

const deleteCustomerMasterXRefUserApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('xref-customer-data/' + id, { headers: headers });
  return result;
};

//get customer master xref details
export const getCustomerMasterXRefDetails = (id) => {
  return (dispatch) => {
    getCustomerMasterXRefDetailsApi(id)
      .then((response) => {
        dispatch(getCustomerMasterXRefDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCustomerMasterXRefDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-customer-data/' + id, { headers: headers });
  return result;
};

export const getCustomerMasterXRefDetailsSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_DETAIL_SUCCESS,
    data,
  };
};

//get list customer master xref
export const getCustomerMasterXRefData = () => {
  return (dispatch) => {
    getCustomerMasterXRefDataApi()
      .then((response) => {
        dispatch(getCustomerMastrXrefDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const getCustomerMasterXRefDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-customer-data?page=1&&limit=0', {
    headers: headers,
  });
  return result;
};

export const getCustomerMastrXrefDataSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_SUCCESS,
    data,
  };
};

//get list customer master xref format
export const getCustomerMastrXrefFormatData = () => {
  return (dispatch) => {
    getCustomerMastrXrefFormatDataApi()
      .then((response) => {
        dispatch(getCustomerMastrXrefFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCustomerMastrXrefFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=XrefCustomerDataFormats',
    { headers: headers },
  );
  return result;
};

export const getCustomerMastrXrefFormatDataSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_FORMAT_SUCCESS,
    data,
  };
};
// get customer master xref format data by id
export const getCustomerMastrXrefFormatDataById = (id, page) => {
  return (dispatch) => {
    getCustomerMastrXrefFormatDataByIdApi(id, page)
      .then((response) => {
        dispatch(
          getCustomerMastrXrefFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCustomerMastrXrefFormatDataByIdApi = (id, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'DirectSalesExternalCalculationFormat') {
    result = axios.get(
      '/upload-format/' +
      id +
      '?app-functionality=DirectSalesExternalCalculationFormat',
      { headers: headers },
    );
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    result = axios.get(
      '/upload-format/' +
      id +
      '?app-functionality=DirectSalesFinancialPostingFormat',
      { headers: headers },
    );
  } else {
    result = axios.get(
      '/upload-format/' + id + '?app-functionality=XrefCustomerDataFormats',
      { headers: headers },
    );
  }
  return result;
};

export const getCustomerMastrXrefFormatDataByIdSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_BY_ID_SUCCESS,
    data,
  };
};
// get customer master xref format fields
export const getCustomerMastrXrefFormatFields = (page) => {
  return (dispatch) => {
    getCustomerMastrXrefFormatFieldsApi(page)
      .then((response) => {
        dispatch(
          getCustomerMastrXrefFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getCustomerMastrXrefFormatFieldsApi = (page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'DirectSalesExternalCalculationFormat') {
    result = axios.get(
      'upload-format-fields?app-functionality=DirectSalesExternalCalculationFormat',
      { headers: headers },
    );
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    result = axios.get(
      'upload-format-fields?app-functionality=DirectSalesFinancialPostingFormat',
      { headers: headers },
    );
  } else {
    result = axios.get(
      'upload-format-fields?app-functionality=XrefCustomerDataFormats',
      { headers: headers },
    );
  }
  return result;
};

export const getCustomerMastrXrefFormatFieldsSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_FIELDS_SUCCESS,
    data,
  };
};

// update customer master xref
export const updateCustomerMastrXrefFormatData = (id, data, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateCustomerMastrXrefFormatDataApi(id, data, page)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Format updated successfully');
          if (page === 'DirectSalesExternalCalculationFormat') {
            history.push('/upload-external-calculation-main');
          } else if (page === 'DirectSalesFinancialPostingFormat') {
            history.push('/upload-external-posting-main');
          } else {
            history.push('/customer-x-master/customer-x-master-excel-upload');
          }
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateCustomerMastrXrefFormatDataApi = (id, data, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'DirectSalesExternalCalculationFormat') {
    result = axios.put(
      '/upload-format/' +
      id +
      '?app-functionality=DirectSalesExternalCalculationFormat',
      data,
      { headers: headers },
    );
  } else if (page === 'DirectSalesFinancialPostingFormat') {
    result = axios.put(
      '/upload-format/' +
      id +
      '?app-functionality=DirectSalesFinancialPostingFormat',
      data,
      { headers: headers },
    );
  } else {
    result = axios.put(
      '/upload-format/' + id + '?app-functionality=XrefCustomerDataFormats',
      data,
      { headers: headers },
    );
  }
  return result;
};
//add customer master xref format
export const addCustomerMastrXrefFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCustomerMastrXrefFormatDataApi(data)
      .then((response) => {
        toast.success('Format created successfully');
        dispatch(fetchEnd());
        history.push('/customer-x-master/customer-x-master-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addCustomerMastrXrefFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('customer-data-upload-format', data, {
    headers: headers,
  });
  return result;
};
export const deleteCustomerMastrXrefFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteCustomerMastrXrefFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          history.push('/customer-x-master/customer-x-master-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteCustomerMastrXrefFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File customer master xref
export const uploadCustomerMastrXrefFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCustomerMastrXrefFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadCustomerMasterSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.errorRows);
      });
  };
};

const uploadCustomerMastrXrefFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('xref-customer-data-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadCustomerMasterSuccess = (data) => {
  return {
    type: CUSTOMER_MASTER_XREF_UPLOAD_SUCCESS,
    data,
  };
};

//material master
//get list of material master data
export const getMaterialMasterData = (pagination, limit, keyword, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMaterialMasterDataApi(pagination, limit, keyword, data)
      .then((response) => {
        if (keyword === 'search') {
          dispatch(fetchEnd());
          dispatch(getMaterialMastrDataSuccess(response.data.response));
          history.push({
            pathname: '/material-master',
            state: 'displayAPICALL',
          });
        } else {
          dispatch(fetchEnd());
          dispatch(getMaterialMastrDataSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getMaterialMasterDataApi = (pagination, limit, keyword, data) => {
  if (keyword === 'search') {
    for (var propName in data) {
      if (
        data[propName] === '' ||
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName].length === 0 ||
        data[propName] ===
        '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
        data[propName] === '1970-01-01T00:00:00.000Z' ||
        data[propName] === ','
      ) {
        delete data[propName];
      }
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword === 'search') {
    result = axios.post(
      'master-data-search?page=' +
      pagination +
      '&&limit=' +
      0 +
      '&&table_name=material_master',
      data,
      { headers: headers },
    );
  } else {
    if (pagination === 'NoLimit') {
      result = axios.get('material-master?limit=0', { headers: headers });
    }
    // else if (keyword) {
    //   result = axios.get("material-master?keyword=" + keyword, {
    //     headers: headers,
    //   });
    // }
    else {
      result = axios.get(
        'material-master?page=' + pagination + '&&limit=' + limit,
        { headers: headers },
      );
    }
  }
  return result;
};

export const getMaterialMastrDataSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_SUCCESS,
    data,
  };
};

//material master user
export const addMaterialMasterUser = (data, uom) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMaterialMasterUserApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (uom.length > 0) {
          addMaterialMasterUoMApi(uom, response.data.response.material_number)
            .then((response) => {
              dispatch(fetchEnd());
            })
            .catch((error) => {
              dispatch(fetchEnd());
            });
        }
        // history.push('/material-master-promo');
        history.goBack();
        toast.success('Successfully added.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addMaterialMasterUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('material-master', data, { headers: headers });
  return result;
};
//add material uom

const addMaterialMasterUoMApi = (data, id) => {
  var uom = [];
  data.map((item, index) => {
    uom.push({ ...item, material_number: id });
  });
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('material-uom', uom, { headers: headers });
  return result;
};

//edit material master
export const editMaterialMasterUser = (data, id, uomData, uomId) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editMaterialMasterUserApi(data, id)
      .then((response) => {
        dispatch(
          getMaterialMasterData(
            1,
            0,
            'search',
            JSON.parse(sessionStorage.getItem('search-data')),
          ),
        );
        dispatch(
          getMaterialMasterData(
            1,
            0,
            'search',
            JSON.parse(sessionStorage.getItem('search-data')),
          ),
        );
        dispatch(fetchEnd());
        if (uomData.length >= 0) {
          editMaterialMasterUoMApi(uomData, data.material_number)
            .then((response) => {
              dispatch(fetchEnd());
              history.goBack();
              // if (window.location.href.indexOf('material-master-promo') > -1) {
              //     history.push('/material-master-promo');
              // } else {
              //     history.push('/material-master');
              // }
              toast.success('Updated successfully');
            })
            .catch((error) => {
              dispatch(fetchEnd());
            });
        } else {
          if (window.location.href.indexOf('material-master-promo') > -1) {
            history.push('/material-master-promo');
          } else {
            history.push('/material-master');
          }
          toast.success('Updated successfully');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editMaterialMasterUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('material-master/' + id, data, { headers: headers });
  return result;
};
//edit material uom
const editMaterialMasterUoMApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('material-uom/' + id, data, { headers: headers });
  return result;
};

//material master detail view
export const getMaterialMasterViewDetails = (id, mode) => {
  return (dispatch) => {
    getMaterialMasterViewDetailsApi(id).then((response) => {
      if (mode != 'edit') history.push('/material-master/view-material/' + id);
      dispatch(materialMasterViewDetailsSuccess(response.data.response));
    });
  };
};
const getMaterialMasterViewDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('material-master/' + id, { headers: headers });
  return result;
};

export const materialMasterViewDetailsSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_VIEW_DETAIL_SUCCESS,
    data,
  };
};

//material master x ref
//add material master xref
export const addMaterialMasterXRefUser = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMaterialMasterXRefUserApi(data)
      .then((response) => {
        toast.success('Successfully added.');
        dispatch(getMaterialMasterXRefData());
        dispatch(fetchEnd());
        history.push('/material-x-master');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addMaterialMasterXRefUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('xref-material-master', data, { headers: headers });
  return result;
};
//edit material master xref
export const editMaterialMasterXRefUser = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editMaterialMasterXRefUserApi(data, id)
      .then((response) => {
        toast.success('Successfully updated.');
        history.push('/material-x-master');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editMaterialMasterXRefUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('xref-material-master/' + id, data, {
      headers: headers,
    });
  } else {
    result = axios.post('xref-material-master', data, { headers: headers });
  }
  return result;
};
//delete material master x
export const deleteMaterialMasterXRefUser = (id) => {
  return (dispatch) => {
    deleteMaterialMasterXRefUserApi(id)
      .then((response) => {
        toast.success('Successfully deleted.');
      })
      .catch((error) => { });
  };
};

const deleteMaterialMasterXRefUserApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('xref-customer-data/' + id, { headers: headers });
  return result;
};

//get material master x detail view
export const getMaterialMasterXRefDetails = (id) => {
  return (dispatch) => {
    getMaterialMasterXRefDetailsApi(id)
      .then((response) => {
        dispatch(getMaterialMasterXRefDetailsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getMaterialMasterXRefDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-material-master/' + id, { headers: headers });
  return result;
};

export const getMaterialMasterXRefDetailsSuccess = (data) => {
  return {
    type: MATERIAL_XREF_MASTER_VIEW_DETAIL_SUCCESS,
    data,
  };
};
//get list of material master x data
export const getMaterialMasterXRefData = (pagination, limit, keyword) => {
  return (dispatch) => {
    getMaterialMasterXRefDataApi(pagination, limit, keyword)
      .then((response) => {
        dispatch(getMaterialMasterXRefDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getMaterialMasterXRefDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword) {
    result = axios.get('xref-material-master?keyword=' + keyword, {
      headers: headers,
    });
  } else {
    result = axios.get('xref-material-master?page=1' + '&&limit=0', {
      headers: headers,
    });
  }
  return result;
};

export const getMaterialMasterXRefDataSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_XREF_SUCCESS,
    data,
  };
};
//get list material master xref format
export const getMaterialMastrXrefFormatData = () => {
  return (dispatch) => {
    getMaterialMastrXrefFormatDataApi()
      .then((response) => {
        dispatch(getMaterialMastrXrefFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMaterialMastrXrefFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('materialxref-master-data-formats', { headers: headers });
  return result;
};

export const getMaterialMastrXrefFormatDataSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_XREF_FORMAT_SUCCESS,
    data,
  };
};
// get material master xref format data by id
export const getMaterialMastrXrefFormatDataById = (id) => {
  return (dispatch) => {
    getMaterialMastrXrefFormatDataByIdApi(id)
      .then((response) => {
        dispatch(
          getMaterialMastrXrefFormatDataByIdSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMaterialMastrXrefFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=XrefMaterialMasterDataFormats',
    { headers: headers },
  );
  return result;
};

export const getMaterialMastrXrefFormatDataByIdSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_XREF_BY_ID_SUCCESS,
    data,
  };
};
// get material master xref format fields
export const getMaterialMastrXrefFormatFields = () => {
  return (dispatch) => {
    getMaterialMastrXrefFormatFieldsApi()
      .then((response) => {
        dispatch(
          getMaterialMastrXrefFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMaterialMastrXrefFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('materialxref-master-data-format-fields', {
    headers: headers,
  });
  return result;
};

export const getMaterialMastrXrefFormatFieldsSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_XREF_FIELDS_SUCCESS,
    data,
  };
};
// update material master xref
export const updateMaterialMastrXrefFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateMaterialMastrXrefFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Format updated successfully');
          history.push('/material-x-master/material-x-master-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateMaterialMastrXrefFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('materialxref-master-data-format/' + id, data, {
    headers: headers,
  });
  return result;
};
//add material master xref format
export const addMaterialMastrXrefFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMaterialMastrXrefFormatDataApi(data)
      .then((response) => {
        toast.success('Format created successfully');
        dispatch(fetchEnd());
        history.push('/material-x-master/material-x-master-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addMaterialMastrXrefFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('materialxref-master-data-upload-format', data, {
    headers: headers,
  });
  return result;
};
// delete material master xref format
export const deleteMaterialMastrXrefFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteMaterialMastrXrefFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          history.push('/material-x-master/material-x-master-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteMaterialMastrXrefFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('materialxref-master-data-format/' + id, {
    headers: headers,
  });
  return result;
};
//upload File material master xref
export const uploadMaterialMastrXrefFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMaterialMastrXrefFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadMaterialMasterXREFSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.errorRows);
      });
  };
};

const uploadMaterialMastrXrefFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post(
    'xref-material-master-data-upload?format_id=' + id,
    file,
    { headers: headers },
  );
  return result;
};
export const uploadMaterialMasterXREFSuccess = (data) => {
  return {
    type: MATERIAL_MASTER_XREF_UPLOAD_SUCCESS,
    data,
  };
};
//supplier master
//get list of supplier master data
export const getSupplierMasterData = (pagination, limit, keyword, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getSupplierMasterDataApi(pagination, limit, keyword, data)
      .then((response) => {
        if (keyword === 'search') {
          dispatch(fetchEnd());
          dispatch(getSupplierMasterDataSuccess(response.data.response));
          history.push('/supplier-master');
        } else {
          dispatch(fetchEnd());
          dispatch(getSupplierMasterDataSuccess(response.data.response));
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getSupplierMasterDataApi = (pagination, limit, keyword, data) => {
  if (keyword === 'search') {
    for (var propName in data) {
      if (
        data[propName] === '' ||
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName].length === 0 ||
        data[propName] ===
        '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
        data[propName] === '1970-01-01T00:00:00.000Z' ||
        data[propName] === ','
      ) {
        delete data[propName];
      }
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  // if (keyword) {
  //   result = axios.get("supplier-master?keyword=" + keyword, {
  //     headers: headers,
  //   });
  // }
  if (keyword === 'search') {
    result = axios.post(
      'master-data-search?page=' +
      pagination +
      '&&limit=' +
      0 +
      '&&table_name=supplier_master',
      data,
      { headers: headers },
    );
  } else {
    result = axios.get('supplier-master?page=1' + '&&limit=0', {
      headers: headers,
    });
  }
  return result;
};

export const getSupplierMasterDataSuccess = (data) => {
  return {
    type: SUPPLIER_MASTER_SUCCESS,
    data,
  };
};

//supplier master add
export const addSupplierMasterData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addSupplierMasterDataApi(data)
      .then((response) => {
        history.push('/supplier-master');
        toast.success('Successfully added.');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addSupplierMasterDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('supplier-master', data, { headers: headers });
  return result;
};

//material master detail view
export const getSupplierMasterViewDetails = (id, mode) => {
  return (dispatch) => {
    getSupplierMasterViewDetailsApi(id)
      .then((response) => {
        if (mode != 'edit')
          history.push('/supplier-master/view-supplier/' + id);
        dispatch(supplierMasterViewDetailsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getSupplierMasterViewDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('supplier-master/' + id, { headers: headers });
  return result;
};

export const supplierMasterViewDetailsSuccess = (data) => {
  return {
    type: SUPPLIER_MASTER_VIEW_DETAIL_SUCCESS,
    data,
  };
};
// Acquire Lock
export const getAcquireLockDetails = (data, type) => {
  return (dispatch) => {
    getAcquireLockDetailsApi(data, type)
      .then((response) => {
        // toast.success(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == '409') {
          toast.error(error.response.data.response);
          if (type == 'contract_data') {
            sessionStorage.removeItem('functionality-name');
            history.push('/contract-setup/view-contract/' + data.id);
          } else {
            history.push('/supplier-master/view-supplier/' + data.id);
          }
        } else if (error.response.status == '501') {
        } else {
          toast.error(error.response.data.response);
        }
      });
  };
};
const getAcquireLockDetailsApi = (data, type) => {
  const config = {
    headers: {
      Authorization: secureLocalStorage.getItem('aTk'),
    },
    params: data,
  };
  var result;
  result = axios.get('acquire-lock', config);
  return result;
};

// Release Lock
export const getReleaseLockDetails = (data) => {
  return (dispatch) => {
    getReleaseLockDetailsApi(data)
      .then((response) => {
        // toast.success(response.data.response);
      })
      .catch((error) => {
        if (error.response.status == '501') {
        } else {
          toast.error(error.response.data.response);
        }
      });
  };
};
const getReleaseLockDetailsApi = (data) => {
  const config = {
    headers: {
      Authorization: secureLocalStorage.getItem('aTk'),
    },
    params: data,
  };
  var result;
  result = axios.get('release-lock', config);
  return result;
};

//supplier master edit
export const editSupplierMasterData = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editSupplierMasterDataApi(data, id)
      .then((response) => {
        dispatch(
          getSupplierMasterData(
            1,
            0,
            'search',
            JSON.parse(sessionStorage.getItem('search-data')),
          ),
        );
        history.push('/supplier-master');
        toast.success('Updated Successfully.');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editSupplierMasterDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('supplier-master/' + id, data, { headers: headers });
  return result;
};

// list of items
export const getCustomerMasterXRefDataSearch = (pagination, limit, keyword) => {
  return (dispatch) => {
    getCustomerMasterXRefDataSearchApi(pagination, limit, keyword)
      .then((response) => {
        dispatch(getCustomerMastrXrefDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getCustomerMasterXRefDataSearchApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'xref-customer-data?keyword=' +
    keyword +
    '&page=' +
    pagination +
    '&&limit=' +
    limit,
    { headers: headers },
  );
  return result;
};

//get default values customer master x ref
export const getDefaultValuesCustomerXRef = () => {
  return (dispatch) => {
    getDefaultValuesCustomerXRefApi()
      .then((response) => {
        dispatch(getDefaultValuesCustomerXRefSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error('Something went wrong!')
      });
  };
};

const getDefaultValuesCustomerXRefApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_XREF_CUSTOMER_MASTER&section=0&limit=0',
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesCustomerXRefSuccess = (data) => {
  return {
    type: IMA_CONFIG_XREF_CUSTOMER_MASTER_SUCCESS,
    data,
  };
};

//get default values
export const getDefaultValuesCustomer = () => {
  return (dispatch) => {
    getDefaultValuesCustomerApi()
      .then((response) => {
        dispatch(getDefaultValuesCustomerApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getDefaultValuesCustomerApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_CUSTOMER_MASTER&limit=0',
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesCustomerApiSuccess = (data) => {
  return {
    type: IMA_CONFIG_CUSTOMER_MASTER_SUCCESS,
    data,
  };
};
//material master default values
export const getDefaultValuesMaterial = () => {
  return (dispatch) => {
    getDefaultValuesMaterialApi()
      .then((response) => {
        dispatch(getDefaultValuesMaterialApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getDefaultValuesMaterialApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_MATERIAL_MASTER&limit=0',
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesMaterialApiSuccess = (data) => {
  return {
    type: IMA_CONFIG_MATERIAL_MASTER_SUCCESS,
    data,
  };
};
//supplier master default values
export const getDefaultValuesSupplier = () => {
  return (dispatch) => {
    getDefaultValuesSupplierApi()
      .then((response) => {
        dispatch(getDefaultValuesSupplierApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getDefaultValuesSupplierApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_SUPPLIER_MASTER&limit=0',
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesSupplierApiSuccess = (data) => {
  return {
    type: IMA_CONFIG_SUPPLIER_SUCCESS,
    data,
  };
};

//Supplier master dropdown options

export const getSupplierMasterDropDown = () => {
  return (dispatch) => {
    getSupplierMasterDropDownApi()
      .then((response) => {
        dispatch(getSupplierMasterDropDownApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getSupplierMasterDropDownApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('supplier-master-dropdown-options', {
    headers: headers,
  });
  return result;
};

export const getSupplierMasterDropDownApiSuccess = (data) => {
  return {
    type: SUPPLIER_MASTER_DROPDOWN_SUCCESS,
    data,
  };
};

//get default values material x ref
export const getDefaultValuesMaterialXRef = () => {
  return (dispatch) => {
    getDefaultValuesMaterialXRefApi()
      .then((response) => {
        dispatch(
          getDefaultValuesMaterialXRefApiSuccess(response.data.response),
        );
      })
      .catch((error) => { });
  };
};

const getDefaultValuesMaterialXRefApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'form_fields?form_id=IMA_CONFIG_XREF_MATERIAL_MASTER&section=0&limit=0',
    { headers: headers },
  );
  return result;
};

export const getDefaultValuesMaterialXRefApiSuccess = (data) => {
  return {
    type: IMA_CONFIG_XREF_MATERIAL_MASTER_SUCCESS,
    data,
  };
};

//add membership
export const addMembership = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMembershipApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.data.response.error != null) {
          //toast.error(response.data.response.error.toString());
          if (response.data.response.error != null) {
            toast.error(response.data.response.error.toString());
          } else {
            history.push('/membership');
          }
        } else {
          toast.success('Successfully added.');
          history.push('/membership');
        }
      })
      .catch((error) => { });
  };
};

const addMembershipApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('membership', data, { headers: headers });
  return result;
};

//edit membership
export const editMembership = (data, id) => {
  return (dispatch) => {
    editMembershipApi(data, id).then((response) => {
      history.push('/membership');
      toast.success('Successfully updated.');
    });
  };
};

const editMembershipApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('membership/' + id, data, { headers: headers });
  } else {
    result = axios.post('membership', data, { headers: headers });
  }
  return result;
};

//delete membership
export const deleteMembership = (id, type, memberID) => {
  return (dispatch) => {
    deleteMembershipApi(id, type)
      .then((response) => {
        toast.success(response.data.response);
        if (type == 'multiple') dispatch(getMembershipData());
        else dispatch(getMembershipDetails(memberID, 'MembershipID'));
      })
      .catch((error) => {
        //toast.error('Something went wrong when deleting. ')
      });
  };
};

const deleteMembershipApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'multiple')
    result = axios.delete('membership/' + id + '?multiple=true', {
      headers: headers,
    });
  else result = axios.delete('membership/' + id, { headers: headers });
  return result;
};

//get membership details
export const getMembershipDetails = (id, type) => {
  return (dispatch) => {
    getMembershipDetailsApi(id, type)
      .then((response) => {
        dispatch(getMembershipDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipDetailsApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'MembershipID') {
    result = axios.get(
      'membership?membership_owner_id=' + id + '&page=1&limit=0',
      { headers: headers },
    );
  } else {
    result = axios.get('membership/' + id, { headers: headers });
  }
  return result;
};

export const getMembershipDetailsSuccess = (data) => {
  return {
    type: MEMBERSHIP_DETAIL_SUCCESS,
    data,
  };
};

//get list membership
export const getMembershipData = (pagination, limit, keyword, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMembershipDataApi(pagination, limit, keyword, data).then((response) => {
      dispatch(fetchEnd());
      if (keyword === 'displaybyFilter') {
        dispatch(getMembershipDataSuccess(response.data.response));
        history.push({
          pathname: '/membership-by-member',
          state: 'displaybyFilter',
        });
      } else {
        dispatch(getMembershipDataSuccess(response.data.response));
      }
    });
  };
};

const getMembershipDataApi = (pagination, limit, keyword, data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword === 'displaybyMember') {
    result = axios.get('membership?display_by_member=true&&page=1&&limit=0', {
      headers: headers,
    });
  } else if (keyword === 'displaybyFilter') {
    result = axios.get(
      'membership?display_by_member=true&&page=1&&limit=0',
      { params: data },
      { headers: headers },
    );
  } else {
    result = axios.get('membership?page=1&&limit=0', { headers: headers });
  }
  return result;
};

export const getMembershipDataSuccess = (data) => {
  return {
    type: MEMBERSHIP_SUCCESS,
    data,
  };
};
// master report api
export const getMasterReportData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getMasterReportDataApi(data).then((response) => {
      dispatch(fetchEnd());
      dispatch(getMasterReportDataSuccess(response.data.response));
      history.push('/master-report-result');
    });
  };
};

const getMasterReportDataApi = (data) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'membership-contract-xref-report',
    { params: data },
    { headers: headers },
  );
  return result;
};

export const getMasterReportDataSuccess = (data) => {
  return {
    type: MASTER_REPORT_SUCCESS,
    data,
  };
};
//get list membership format
export const getMembershipFormatData = (type) => {
  return (dispatch) => {
    getMembershipFormatDataApi(type)
      .then((response) => {
        dispatch(getMembershipFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipFormatDataApi = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=' + type, {
    headers: headers,
  });
  return result;
};

export const getMembershipFormatDataSuccess = (data) => {
  return {
    type: MEMBERSHIP_FORMAT_SUCCESS,
    data,
  };
};

//get membership format data by id
export const getMembershipFormatDataById = (id, type) => {
  return (dispatch) => {
    getMembershipFormatDataByIdApi(id, type)
      .then((response) => {
        dispatch(getMembershipFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipFormatDataByIdApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id + '?app-functionality=' + type, {
    headers: headers,
  });
  return result;
};

export const getMembershipFormatDataByIdSuccess = (data) => {
  return {
    type: MEMBERSHIP_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get membership format fields
export const getMembershipFormatFields = (type) => {
  return (dispatch) => {
    getMembershipFormatFieldsApi(type)
      .then((response) => {
        dispatch(getMembershipFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipFormatFieldsApi = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format-fields?app-functionality=' + type, {
    headers: headers,
  });
  return result;
};

export const getMembershipFormatFieldsSuccess = (data) => {
  return {
    type: MEMBERSHIP_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update membership format
export const updateMembershipFormatData = (id, data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateMembershipFormatDataApi(id, data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push({
            pathname: '/membership/membership-excel-upload',
            state: type,
          });
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateMembershipFormatDataApi = (id, data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=' + type,
    data,
    { headers: headers },
  );
  return result;
};

//add membership format
export const addMembershipFormatData = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMembershipFormatDataApi(data, type)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push({
          pathname: '/membership/membership-excel-upload',
          state: type,
        });
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addMembershipFormatDataApi = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('upload-format?app-functionality=' + type, data, {
    headers: headers,
  });
  return result;
};

// delete membership format
export const deleteMembershipFormatData = (id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteMembershipFormatDataApi(id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getMembershipFormatData(type));
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteMembershipFormatDataApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

export const uploadMembershipFile = (id, file, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMembershipFileApi(id, file, type)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadMembershipSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadMembershipFileApi = (id, file, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (
    type === 'MembershipGPORosterFormats' ||
    type === 'MembershipGPOTierActivationFormats' ||
    type === 'MembershipContractXREFFormats'
  ) {
    result = axios.post('upload-file?format_id=' + id, file, {
      headers: headers,
    });
  } else {
    result = axios.post('membership-data-upload?format_id=' + id, file, {
      headers: headers,
    });
  }
  return result;
};
export const uploadMembershipSuccess = (data) => {
  return {
    type: MEMBERSHIP_UPLOAD_SUCCESS,
    data,
  };
};
// membership contract Group
export const getMembershipContractGroupFormatData = (type) => {
  return (dispatch) => {
    getMembershipContractGroupFormatDataApi(type)
      .then((response) => {
        dispatch(
          getMembershipContractGroupFormatDataSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipContractGroupFormatDataApi = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format?app-functionality=MembershipContractGroupFormats',
    {
      headers: headers,
    },
  );
  return result;
};

export const getMembershipContractGroupFormatDataSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_FORMAT_SUCCESS,
    data,
  };
};

//get membership contract Group format data by id
export const getMembershipContractGroupFormatDataById = (id, type) => {
  return (dispatch) => {
    getMembershipContractGroupFormatDataByIdApi(id, type)
      .then((response) => {
        dispatch(
          getMembershipContractGroupFormatDataByIdSuccess(
            response.data.response,
          ),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipContractGroupFormatDataByIdApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=MembershipContractGroupFormats',
    { headers: headers },
  );
  return result;
};

export const getMembershipContractGroupFormatDataByIdSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get membership contract Group format fields
export const getMembershipContractGroupFormatFields = (type) => {
  return (dispatch) => {
    getMembershipContractGroupFormatFieldsApi(type)
      .then((response) => {
        dispatch(
          getMembershipContractGroupFormatFieldsSuccess(response.data.response),
        );
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getMembershipContractGroupFormatFieldsApi = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=MembershipContractGroupFormats',
    { headers: headers },
  );
  return result;
};

export const getMembershipContractGroupFormatFieldsSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update membership contract Group format
export const updateMembershipContractGroupFormatData = (id, data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateMembershipContractGroupFormatDataApi(id, data, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push({
            pathname: '/membership-contract-group-excel-upload',
          });
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateMembershipContractGroupFormatDataApi = (id, data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=MembershipContractGroupFormats',
    data,
    { headers: headers },
  );
  return result;
};

//add membership contract Group format
export const addMembershipContractGroupFormatData = (data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addMembershipContractGroupFormatDataApi(data, type)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push({ pathname: '/membership-contract-group-excel-upload' });
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addMembershipContractGroupFormatDataApi = (data, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=MembershipContractGroupFormats',
    data,
    {
      headers: headers,
    },
  );
  return result;
};

// delete membership contract Group format
export const deleteMembershipContractGroupFormatData = (id, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteMembershipContractGroupFormatDataApi(id, type)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getMembershipContractGroupFormatData(type));
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteMembershipContractGroupFormatDataApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

export const uploadMembershipContractGroupFile = (id, file, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadMembershipContractGroupFileApi(id, file, type)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(
            uploadMembershipContractGroupSuccess(response.data.response),
          );
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadMembershipContractGroupFileApi = (id, file, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('upload-file?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadMembershipContractGroupSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_UPLOAD_SUCCESS,
    data,
  };
};
//membership hierarchy
export const getMembershipHierarchyData = (id, type) => {
  return (dispatch) => {
    getMembershipHierarchyDataApi(id, type)
      .then((response) => {
        dispatch(getMembershipHierarchyDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getMembershipHierarchyDataApi = (id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  if (type === 'GPONumber') {
    var result = axios.get('gpo-hierarchy?gpo_member_number=' + id, {
      headers: headers,
    });
  } else {
    var result = axios.get(
      'membership-hierarchy-relation?membership_owner_id=' + id,
      { headers: headers },
    );
  }
  return result;
};

export const getMembershipHierarchyDataSuccess = (data) => {
  return {
    type: MEMBERSHIP_HIERARCHY_SUCCESS,
    data,
  };
};

//add bom
export const addBom = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addBomApi(data)
      .then((response) => {
        toast.success('Successfully added.');
        history.push('/bom');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const addBomApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('bom', data, { headers: headers });
  return result;
};
//sales bundle hierarchy
export const getSalesBundleData = (top_material_number) => {
  return (dispatch) => {
    getSalesBundleDataApi(top_material_number)
      .then((response) => {
        dispatch(getSalesBundleDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getSalesBundleDataApi = (top_material_number) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get(
    'sales-bundle-hierarchy?top_material_number=' + top_material_number,
    { headers: headers },
  );

  return result;
};

export const getSalesBundleDataSuccess = (data) => {
  return {
    type: SALES_BUNDLE_SUCCESS,
    data,
  };
};

//edit bom
export const editBom = (data, id) => {
  return (dispatch) => {
    editBomApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/bom');
    });
  };
};

const editBomApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('bom/' + id, data, { headers: headers });
  } else {
    result = axios.post('bom', data, { headers: headers });
  }
  return result;
};

//delete bom
export const deleteBom = (id) => {
  return (dispatch) => {
    deleteBomApi(id)
      .then((response) => {
        toast.success('Successfully deleted.');
      })
      .catch((error) => {
        //toast.error('Something went wrong when deleting. ')
      });
  };
};

const deleteBomApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('bom/' + id, { headers: headers });
  return result;
};

//get bom details
export const getBomDetails = (id) => {
  return (dispatch) => {
    getBomDetailsApi(id)
      .then((response) => {
        dispatch(getBomDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getBomDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('bom/' + id, { headers: headers });
  return result;
};

export const getBomDetailsSuccess = (data) => {
  return {
    type: BOM_DETAIL_SUCCESS,
    data,
  };
};

//get list bom
export const getBomData = () => {
  return (dispatch) => {
    getBomDataApi()
      .then((response) => {
        dispatch(getBomDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getBomDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('bom?page=1&&limit=0', { headers: headers });
  //result = axios.get('sales-bundle-data-distinct', { headers: headers });
  return result;
};

export const getBomDataSuccess = (data) => {
  return {
    type: BOM_SUCCESS,
    data,
  };
};

// get list Bom format
export const getBomFormatData = () => {
  return (dispatch) => {
    getBomFormatDataApi()
      .then((response) => {
        dispatch(getBomFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getBomFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=BomFormats', {
    headers: headers,
  });
  return result;
};

export const getBomFormatDataSuccess = (data) => {
  return {
    type: BOM_FORMAT_SUCCESS,
    data,
  };
};

//get bom format data by id
export const getBomFormatDataById = (id) => {
  return (dispatch) => {
    getBomFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getBomFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getBomFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format/' + id + '?app-functionality=BomFormats', {
    headers: headers,
  });
  return result;
};

export const getBomFormatDataByIdSuccess = (data) => {
  return {
    type: BOM_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get bom format fields
export const getBomFormatFields = () => {
  return (dispatch) => {
    getBomFormatFieldsApi()
      .then((response) => {
        dispatch(getBomFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getBomFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format-fields?app-functionality=BomFormats', {
    headers: headers,
  });
  return result;
};

export const getBomFormatFieldsSuccess = (data) => {
  return {
    type: BOM_FORMAT_FIELDS_SUCCESS,
    data,
  };
};
// update bom format
export const updateBomFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateBomFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Format updated successfully');
          history.push('/bom/bom-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateBomFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=BomFormats',
    data,
    { headers: headers },
  );
  return result;
};
//add bom format
export const addBomFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addBomFormatDataApi(data)
      .then((response) => {
        toast.success('Format created successfully');
        dispatch(fetchEnd());
        history.push('/bom/bom-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addBomFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('upload-format?app-functionality=BomFormats', data, {
    headers: headers,
  });

  return result;
};
// delete bom format
export const deleteBomFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteBomFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          history.push('/bom/bom-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteBomFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};
//upload File
export const uploadBomFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadBomFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadBomSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("The file is not according to the format " + error.response.data.complete_error_message)
      });
  };
};
const uploadBomFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('bom-excel-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadBomSuccess = (data) => {
  return {
    type: BOM_UPLOAD_SUCCESS,
    data,
  };
};
//get employee data
export const getEmployeeData = (pagination, limit) => {
  return (dispatch) => {
    getEmployeeDataApi(pagination, limit)
      .then((response) => {
        dispatch(getEmployeeDataApiSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getEmployeeDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  let result;
  result = axios.get('employee?page=1&&limit=0', { headers: headers });
  return result;
};

export const getEmployeeDataApiSuccess = (data) => {
  return {
    type: EMPLOYEE_MASTER_SUCCESS,
    data,
  };
};
//add employee
export const addEmployeeData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addEmployeeApi(data)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success('Successfully added.');
        history.push('/employee');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addEmployeeApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('employee', data, { headers: headers });
  return result;
};

export const getEmployeeMasterViewDetails = (id, mode) => {
  return (dispatch) => {
    getEmployeeMasterViewDetailsApi(id).then((response) => {
      if (mode != 'edit') history.push('/employee/view-employee/' + id);
      dispatch(getEmployeeMasterViewDetailsApiSuccess(response.data.response));
    });
  };
};
const getEmployeeMasterViewDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('employee/' + id, { headers: headers });
  return result;
};

export const getEmployeeMasterViewDetailsApiSuccess = (data) => {
  return {
    type: EMPLOYEE_MASTER_VIEW_DETAIL_SUCCESS,
    data,
  };
};

export const putEmployeeData = (data, id) => {
  return (dispatch) => {
    putEmployeeDataApi(data, id).then((response) => {
      toast.success('Successfully updated.');
      history.push('/employee');
    });
  };
};

const putEmployeeDataApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('employee/' + id, data, { headers: headers });
  return result;
};

//integration studio
export const addIntegrationSetup = (data) => {
  return (dispatch) => {
    addIntegrationSetupApi(data)
      .then((response) => {
        history.push('/integration-setup');
        toast.success('Successfully added.');
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const addIntegrationSetupApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'integration-setup?application_type=' +
    sessionStorage.getItem('application'),
    data,
    { headers: headers },
  );
  return result;
};

// get filter name dropdown list
export const getFilterNameList = (activity, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFilterNameApi(activity, type)
      .then((response) => {
        dispatch(getFilterNameDataSuccess(response.data.filter_name));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(getFilterListDataFailed(error));
      });
  };
};

const getFilterNameApi = (activity, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'date') {
    var result = axios.get(
      'integration-setup/filters?application_type=' +
      sessionStorage.getItem('application') +
      '&activity=' +
      activity +
      '&type=date',
      { headers: headers },
    );
  } else {
    var result = axios.get(
      'integration-setup/filters?application_type=' +
      sessionStorage.getItem('application') +
      '&activity=' +
      activity,
      { headers: headers },
    );
  }
  return result;
};
export const getFilterNameDataSuccess = (data) => {
  return {
    type: FILTER_LIST_SUCCESS,
    data,
  };
};
export const getFilterListDataFailed = (error) => {
  return {
    type: FILTER_LIST_FAIL,
    error,
  };
};

// get filter by Id for integration manager
export const getFilterById = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getFilterByIdApi(id)
      .then((response) => {
        dispatch(getFilterByIdDataSuccess(response.data.filters));
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getFilterByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'integration-setup/filters/' +
    id +
    '?application_type=' +
    sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};
export const getFilterByIdDataSuccess = (data) => {
  return {
    type: FILTER_BY_ID_LIST_SUCCESS,
    data,
  };
};
// list integration setup
export const getIntegrationSetupData = (pagination, limit, activity) => {
  return (dispatch) => {
    getIntegrationSetupDataApi(pagination, limit, activity)
      .then((response) => {
        dispatch(getIntegrationSetupDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const getIntegrationSetupDataApi = (pagination, limit, activity) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (activity) {
    result = axios.get(
      'integration-setup?application_type=' +
      sessionStorage.getItem('application') +
      '&&page=' +
      pagination +
      '&&limit=' +
      limit +
      '&&activity=' +
      activity,
      { headers: headers },
    );
  } else {
    result = axios.get(
      'integration-setup?application_type=' +
      sessionStorage.getItem('application') +
      '&&page=' +
      pagination +
      '&&limit=' +
      limit,
      { headers: headers },
    );
  }
  return result;
};

export const getIntegrationSetupDataSuccess = (data) => {
  return {
    type: INTEGRATION_SETUP_SUCCESS,
    data,
  };
};

// Track User Activity

export const getTrackActivityData = () => {
  return (dispatch) => {
    getTrackActivityDataApi()
      .then((response) => {
        dispatch(getTrackActivityDataSuccess(response.data));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const getTrackActivityDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('track-user', { headers: headers });
  return result;
};

export const getTrackActivityDataSuccess = (data) => {
  return {
    type: TRACK_ACTIVITY_SUCCESS,
    data,
  };
};

//Trigger integration
export const triggerIntegration = (id, data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    triggerIntegrationApi(id, data, type)
      .then((response) => {
        // if (response.data.response && response.data.response.error) {
        //   dispatch(fetchEnd());
        //   toast.error(response.data.response.error.join(', '));
        //   return; // Exit early to avoid executing the success logic
        // }
        if (type == 'Postings - Customer-Material') {
          dispatch(fetchEnd());
          toast.success(response.data.response['message'])
          // toast.success(
          //   'Records Saved Count ' +
          //   response.data.response['records Processed'],
          // );
          if (type == 'Postings - Customer-Material') {
            const element = document.createElement('a');
            const file = new Blob([response.data.response.data], {
              type: 'text/csv',
            });
            element.href = URL.createObjectURL(file);
            element.download = response.data.response.file_name;
            document.body.appendChild(element);
            element.click();
          }
        } else if (
          type === 'Document Management' ||
          type === 'Sales Reconcilation' ||
          [
            'Customer Master',
            'Material Master',
            'Supplier Master',
            'Direct - Sales Data',
            'Customer Stat Data',
            'Legacy Calculation Data',
          ].includes(type)
        ) {
          dispatch(fetchEnd());
          toast.success(response.data.response['message'])
          // toast.success(
          //   'Records Processed Count ' +
          //   response.data.response['records_saved_count'],
          // );
        } else {
          dispatch(fetchEnd());
          toast.success(response.data.response['message'])
          // toast.success(
          //   'Records Processed: ' + response.data.response['recordsProcessed'],
          // );
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const triggerIntegrationApi = (id, data, type) => {
  try {
    const headers = {
      Authorization: secureLocalStorage.getItem('aTk'),
    };
    var result;
    result = axios.post(
      'integration-setup/' +
      id +
      '/manual' +
      '?application_type=' +
      sessionStorage.getItem('application'),
      data,
      { headers: headers },
    );
    return result;
  } catch (error) {
    console.log(error);
  }
};

//integration studio edit

// fetch integration setup
export const fetchIntegrationSetup = (id) => {
  return (dispatch) => {
    fetchIntegrationSetupApi(id)
      .then((response) => {
        dispatch(fetchIntegrationSetupDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error("Unidentified error");
      });
  };
};

const fetchIntegrationSetupApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('integration-setup/' + id, { headers: headers });
  return result;
};
export const fetchIntegrationSetupDataSuccess = (data) => {
  return {
    type: FECTCH_INTEGRATION_SETUP_SUCCESS,
    data,
  };
};

export const editIntegrationSetup = (id, data) => {
  return (dispatch) => {
    editIntegrationSetupApi(id, data)
      .then((response) => {
        history.push('/integration-setup');
        toast.success('Successfully updated.');
        dispatch(getIntegrationSetupData(1, 20));
      })
      .catch((error) => {
        //toast.error("Unidentified error");
      });
  };
};

const editIntegrationSetupApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'integration-setup/' +
    id +
    '?application_type=' +
    sessionStorage.getItem('application'),
    data,
    { headers: headers },
  );
  return result;
};
// delete integration

export const deleteIntegrationSetup = (id) => {
  return (dispatch) => {
    deleteIntegrationSetupApi(id)
      .then((response) => {
        history.push('/integration-setup');
        toast.success(response.data.response);
        dispatch(getIntegrationSetupData(1, 20));
      })
      .catch((error) => {
        //toast.error("Unidentified error");
      });
  };
};

const deleteIntegrationSetupApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete(
    'integration-setup/' +
    id +
    '?application_type=' +
    sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};

// Integration Manager history
export const imHistory = (id, pagination, limit) => {
  return (dispatch) => {
    imHistoryAPI(id, pagination, limit).then((response) => {
      dispatch(imHistorySuccess(response.data.response));
      if (response && response.data && response.data.response) {
        history.push('/integration-manager/im-history/' + id);
      } else {
        toast.warn('No Records Found.');
      }
    });
  };
};

const imHistoryAPI = (id, pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    'integration-setup-history/' +
    id +
    '?page=' +
    pagination +
    '&limit=' +
    limit,
    { headers: headers },
  );
  return result;
};

export const imHistorySuccess = (data) => {
  return {
    type: IM_HISTORY_SUCCESS,
    data,
  };
};

//get list all territory
export const getTerritoryData = (pagination, limit, keyword) => {
  return (dispatch) => {
    getTerritoryDataApi(pagination, limit, keyword)
      .then((response) => {
        dispatch(getTerritoryDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getTerritoryDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword) {
    result = axios.get('territory?keyword=' + keyword, { headers: headers });
  } else {
    result = axios.get('territory', { headers: headers });
  }
  return result;
};

export const getTerritoryDataSuccess = (data) => {
  return {
    type: TERRITORY_SUCCESS,
    data,
  };
};
//get territory
export const getTerritoryListData = () => {
  return (dispatch) => {
    getTerritoryListDataApi()
      .then((response) => {
        dispatch(getTerritoryListDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getTerritoryListDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('territory-masters', { headers: headers });

  return result;
};

export const getTerritoryListDataSuccess = (data) => {
  return {
    type: TERRITORY_LIST_SUCCESS,
    data,
  };
};
//single territory

export const getSingleTerritoryData = (id) => {
  return (dispatch) => {
    getSingleTerritoryDataApi(id)
      .then((response) => {
        dispatch(getSingleTerritoryDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getSingleTerritoryDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  //var result = axios.get('territory/' + id, { headers: headers });
  var result = axios.get('territory-hierarchy?id=' + id, { headers: headers });
  return result;
};

export const getSingleTerritoryDataSuccess = (data) => {
  return {
    type: TERRITORY_SINGLE_SUCCESS,
    data,
  };
};

//approval designer
export const getAllApprovalDesigner = (pagination, limit) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAllApprovalDesignerAPI(pagination, limit).then((response) => {
      dispatch(getAllApprovalDesignerSuccess(response.data.response));
      dispatch(fetchEnd());
    });
  };
};

const getAllApprovalDesignerAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('workflow-setup', { headers: headers });
  return result;
};

export const getAllApprovalDesignerSuccess = (data) => {
  return {
    type: APPROVAL_DESIGNER_GET_SUCCESS,
    data,
  };
};

export const addApprovalDesigner = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addApprovalDesignerAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/approval-limits');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addApprovalDesignerAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('workflow-setup/' + id, data, { headers: headers });
  } else {
    result = axios.post('workflow-setup', data, { headers: headers });
  }
  return result;
};

export const getApprovalDesignerDetails = (id) => {
  return (dispatch) => {
    getApprovalDesignerDetailsAPI(id).then((response) => {
      dispatch(getApprovalDesignerDetailsSuccess(response.data.response));
    });
  };
};

const getApprovalDesignerDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('workflow-setup/' + id, { headers: headers });
  return result;
};

export const getApprovalDesignerDetailsSuccess = (data) => {
  return {
    type: APPROVAL_DESIGNER_DETAIL_SUCCESS,
    data,
  };
};

export const deleteApprovalDesigner = (id) => {
  return (dispatch) => {
    deleteApprovalDesignerAPI(id).then((response) => {
      toast.success(response.data.response);
      dispatch(getAllApprovalDesigner());
    });
  };
};

const deleteApprovalDesignerAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('workflow-setup/' + id, { headers: headers });
  return result;
};

//contract default
export const getContractDefaultDesigner = (pagination, limit) => {
  return (dispatch) => {
    getContractDefaultDesignerAPI(pagination, limit).then((response) => {
      dispatch(getContractDefaultDesignerSuccess(response.data.response));
    });
  };
};

const getContractDefaultDesignerAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('default-all?page=' + pagination + '&limit=' + limit, {
    headers: headers,
  });
  return result;
};

export const getContractDefaultDesignerSuccess = (data) => {
  return {
    type: CONTRACT_DEFAULT_DESIGNER_SUCCESS,
    data,
  };
};

export const addContractDefaultDesigner = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addContractDefaultDesignerAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());

        if (type === 'delete') {
          dispatch(getContractDefaultDesigner());
          toast.success('Successfully deleted.');
        } else {
          history.push('/contract-default-designer');
          toast.success('Successfully added.');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addContractDefaultDesignerAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('default/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('default/' + id, data, { headers: headers });
  } else {
    result = axios.post('default', data[0], { headers: headers });
  }
  return result;
};

export const getContractDefaultDetails = (id) => {
  return (dispatch) => {
    getContractDefaultDetailsAPI(id).then((response) => {
      dispatch(getContractDefaultDetailsSuccess(response.data.response));
    });
  };
};

const getContractDefaultDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('default?default_id=' + id, { headers: headers });
  return result;
};

export const getContractDefaultDetailsSuccess = (data) => {
  return {
    type: CONTRACT_DEFAULT_DETAIL_SUCCESS,
    data,
  };
};

//payment designer
export const getAllPaymentDesigner = (pagination, limit) => {
  return (dispatch) => {
    getAllPaymentDesignerAPI(pagination, limit).then((response) => {
      dispatch(getAllPaymentDesignerSuccess(response.data.response));
    });
  };
};

const getAllPaymentDesignerAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'payment-designer-all?page=' + pagination + '&&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getAllPaymentDesignerSuccess = (data) => {
  return {
    type: PAYMENT_DESIGNER_GET_SUCCESS,
    data,
  };
};
export const deletePaymentDesigner = (id) => {
  return (dispatch) => {
    deletePaymentDesignerAPI(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getAllPaymentDesigner());
      })
      .catch((error) => { });
  };
};

const deletePaymentDesignerAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('payment-designer/' + id, { headers: headers });

  return result;
};

//accrual designer
export const getAllAccrualDesigner = (pagination, limit) => {
  return (dispatch) => {
    getAllAccrualDesignerAPI(pagination, limit)
      .then((response) => {
        dispatch(getAllAccrualDesignerSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getAllAccrualDesignerAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'accrual-designer-all?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};

export const getAllAccrualDesignerSuccess = (data) => {
  return {
    type: ACCRUAL_DESIGNER_GET_SUCCESS,
    data,
  };
};

//single
export const getAccrualDesignerSingleItem = (id) => {
  return (dispatch) => {
    getAccrualDesignerSingleItemAPI(id).then((response) => {
      dispatch(getAccrualDesignerSingleItemSuccess(response.data.response));
    });
  };
};

const getAccrualDesignerSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('accrual-designer?id=' + id, { headers: headers });
  return result;
};

export const getAccrualDesignerSingleItemSuccess = (data) => {
  return {
    type: ACCRUAL_DESIGNER_SINGLE_SUCCESS,
    data,
  };
};

//single
export const getPaymentDesignerSingleItem = (id) => {
  return (dispatch) => {
    getPaymentDesignerSingleItemAPI(id).then((response) => {
      dispatch(getPaymentDesignerSingleItemSuccess(response.data.response));
    });
  };
};

const getPaymentDesignerSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('payment-designer?id=' + id, { headers: headers });
  return result;
};

export const getPaymentDesignerSingleItemSuccess = (data) => {
  return {
    type: PAYMENT_DESIGNER_SINGLE_SUCCESS,
    data,
  };
};

export const addPaymentDesigner = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addPaymentDesignerAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/payment-designer');
        toast.success(response.data.response);
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addPaymentDesignerAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('payment-designer/' + id, data, { headers: headers });
  } else {
    result = axios.post('payment-designer', data, { headers: headers });
  }
  return result;
};

export const addAccrualDesigner = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addAccrualDesignerAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());
        history.push('/accrual-designer');
        toast.success('Accrual Designer created successfully.');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addAccrualDesignerAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit') {
    result = axios.put('accrual-designer/' + id, data, { headers: headers });
  } else {
    result = axios.post('accrual-designer', data, { headers: headers });
  }
  return result;
};
export const deleteAccrualDesigner = (id) => {
  return (dispatch) => {
    deleteAccrualDesignerAPI(id).then((response) => {
      toast.success('Successfully deleted');
      dispatch(getAllAccrualDesigner());
    });
  };
};

const deleteAccrualDesignerAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('accrual-designer/' + id, { headers: headers });
  return result;
};
//sales Basis
//get all sales basis
export const getAllSalesBasis = () => {
  return (dispatch) => {
    getAllSalesBasisAPI().then((response) => {
      dispatch(getAllSalesBasisSuccess(response.data.response));
    });
  };
};

const getAllSalesBasisAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('universal-eligibility-rule', { headers: headers });
  return result;
};

export const getAllSalesBasisSuccess = (data) => {
  return {
    type: SALES_BASIS_ALL,
    data,
  };
};
export const getAllSalesBasisSuccessSingle = (data) => {
  return {
    type: SALES_BASIS_SINGLE,
    data,
  };
};

//add eligibility
export const addSalesBasis = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addSalesBasisAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());

        dispatch(getAllSalesBasis());
        toast.success(response.data.response);
        history.push('/sales-basis');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addSalesBasisAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('universal-eligibility-rule/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('universal-eligibility-rule/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('universal-eligibility-rule', data, {
      headers: headers,
    });
  }
  return result;
};

export const getSingleSalesBasis = (id) => {
  return (dispatch) => {
    getSingleSalesBasisAPI(id).then((response) => {
      dispatch(getSingleSalesBasisSuccessSingle(response.data.response));
    });
  };
};

const getSingleSalesBasisAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('universal-eligibility-rule/' + id, { headers: headers });
  return result;
};

export const getSingleSalesBasisSuccessSingle = (data) => {
  return {
    type: SALES_BASIS_SINGLE,
    data,
  };
};

//batch job
export const addBatchJobSetup = (data, edit, id, type) => {
  return (dispatch) => {
    addBatchJobSetupAPI(data, edit, id, type)
      .then((response) => {
        if (type === 'CustomerRebateForecast') {
          history.push('/profitopt-batchjob-setup');
        } else {
          history.push('/batchjob-setup');
        }
        if (edit === 'edit') toast.success('Successfully updated.');
        else if (edit === 'delete') {
          toast.success('Successfully deleted.');
          dispatch(getBatchJobSetup(sessionStorage.getItem('batchJob-Name')));
        } else {
          toast.success('Successfully added.');
        }
      })
      .catch((error) => { });
  };
};

const addBatchJobSetupAPI = (data, edit, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (edit === 'edit')
    result = axios.put('batch-job/' + id, data, { headers: headers });
  else if (edit === 'delete')
    result = axios.delete('batch-job/' + id, data, { headers: headers });
  else result = axios.post('batch-job', data, { headers: headers });
  return result;
};

export const getBatchJobSetup = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBatchJobSetupAPI(type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getBatchJobSetupSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getBatchJobSetupAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('batch-job?app_type=' + type, { headers: headers });
  return result;
};

export const getBatchJobSetupSuccess = (data) => {
  return {
    type: BATCH_JOB_ALL,
    data,
  };
};

export const getBatchJobDetail = (id) => {
  return (dispatch) => {
    getBatchJobDetailAPI(id)
      .then((response) => {
        dispatch(getBatchJobDetailSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getBatchJobDetailAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('batch-job/' + id, { headers: headers });
  return result;
};

export const getBatchJobDetailSuccess = (data) => {
  return {
    type: BATCH_JOB_DETAIL,
    data,
  };
};
// Pricing Batch Job
export const addPricingBatchJobSetup = (data, edit, id, appType) => {
  return (dispatch) => {
    addPricingBatchJobSetupAPI(data, edit, id)
      .then((response) => {
        if (edit === 'edit') {
          toast.success('Successfully updated.');
          if (appType == 'Salse Data') {
            history.push('/transaction-data-batchjob-setup');
          } else {
            history.push('/pricing-batchjob-setup');
          }
        } else if (edit === 'delete') {
          toast.success('Successfully deleted.');
          dispatch(getPricingBatchJobSetup());
          if (appType == 'Salse Data') {
            history.push('/transaction-data-batchjob-setup');
          } else {
            history.push('/pricing-batchjob-setup');
          }
        } else {
          toast.success('Successfully added.');
          if (appType == 'Salse Data') {
            history.push('/transaction-data-batchjob-setup');
          } else {
            history.push('/pricing-batchjob-setup');
          }
        }
      })
      .catch((error) => { });
  };
};

const addPricingBatchJobSetupAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (edit === 'edit')
    result = axios.put('batch-job/' + id, data, { headers: headers });
  else if (edit === 'delete')
    result = axios.delete('batch-job/' + id, { headers: headers });
  else result = axios.post('batch-job', data, { headers: headers });
  return result;
};

export const getPricingBatchJobSetup = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getPricingBatchJobSetupAPI(type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getPricingBatchJobSetupSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getPricingBatchJobSetupAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'Sales Data') {
    result = axios.get('batch-job?app_type=Sales Data', { headers: headers });
  } else {
    result = axios.get('batch-job', { headers: headers });
  }
  return result;
};

export const getPricingBatchJobSetupSuccess = (data) => {
  return {
    type: PRICING_BATCH_JOB_ALL,
    data,
  };
};

export const getPricingBatchJobDetail = (id) => {
  return (dispatch) => {
    getPricingBatchJobDetailAPI(id)
      .then((response) => {
        dispatch(getPricingBatchJobDetailSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getPricingBatchJobDetailAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('batch-job/' + id, { headers: headers });
  return result;
};

export const getPricingBatchJobDetailSuccess = (data) => {
  return {
    type: PRICING_BATCH_JOB_DETAIL,
    data,
  };
};
//  batch job intergration auto params
export const getBatchJobAutoIntegration = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBatchJobAutoIntegrationApi(data, id)
      .then((response) => {
        history.push('/integration-batchjob-setup');
        toast.success('Integration Filter Saved Successfully.');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getBatchJobAutoIntegrationApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('integration-auto-params/' + id, data, {
    headers: headers,
  });
  return result;
};

// territory
export const createTerritory = (data, id, type) => {
  return (dispatch) => {
    createTerritoryAPI(data, id, type)
      .then((response) => {
        toast.success(response.data.response);
        if (type == 'delete') {
          dispatch(getTerritoryListData());
        } else {
          history.push('/terittory');
        }
      })
      .catch((error) => { });
  };
};

const createTerritoryAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('territory/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('territory/' + id, data, { headers: headers });
  } else {
    result = axios.post('territory', data, { headers: headers });
  }
  return result;
};

//exception management
export const getExceptionManagementAll = (id, type, appType) => {
  return (dispatch) => {
    getExceptionManagementAllAPI(id, type, appType).then((response) => {
      if (id) {
        dispatch(getExceptionManagementSingleSuccess(response.data.response));
      } else {
        if (type == 'exception_filter') {
          dispatch(
            getExceptionManagementAllSuccess(response.data.response.records),
          );
          history.push({
            pathname: '/exception-management',
            status: 'from_contract',
          });
        } else {
          dispatch(
            getExceptionManagementAllSuccess(response.data.response.records),
          );
        }
      }
    });
  };
};

const getExceptionManagementAllAPI = (id, type, appType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.get('incentive-override/' + id, { headers: headers });
  } else if (type === 'list') {
    result = axios.get(
      'incentive-override?page=1&&limit=0' + '&application_type=' + appType,
      {
        headers: headers,
      },
    );
  } else if (type === 'exception_filter') {
    result = axios.get(
      'incentive-override?page=1&&limit=0' +
      '&application_type=' +
      sessionStorage.getItem('application') +
      '&contract_number=' +
      appType,
      {
        headers: headers,
      },
    );
  } else {
    result = axios.get(
      'incentive-override?page=1&&limit=0&&approval_status.in=new,new_changes',
      { headers: headers },
    );
  }
  return result;
};

export const getExceptionManagementAllSuccess = (data) => {
  return {
    type: EXCEPTION_MANAGEMENT_ALL,
    data,
  };
};

export const getExceptionManagementSingleSuccess = (data) => {
  return {
    type: EXCEPTION_MANAGEMENT_SINGLE,
    data,
  };
};
//
export const getExceptionManagementViewDetails = (id, mode) => {
  return (dispatch) => {
    getExceptionManagementViewDetailsApi(id).then((response) => {
      if (mode != 'edit') history.push('/exception-management/view/' + id);
      dispatch(
        getExceptionManagementViewDetailsApiSuccess(response.data.response),
      );
    });
  };
};
const getExceptionManagementViewDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('incentive-override/' + id, { headers: headers });
  return result;
};

export const getExceptionManagementViewDetailsApiSuccess = (data) => {
  return {
    type: EXCEPTION_MANAGEMENT_VIEW_DETAIL_SUCCESS,
    data,
  };
};
//

export const addExceptionManagement = (data, type, id) => {
  return (dispatch) => {
    addExceptionManagementAPI(data, type, id)
      .then((response) => {
        if (type == 'delete') {
          toast.success('Successfully deleted.');
          dispatch(
            getExceptionManagementAll(
              null,
              'list',
              sessionStorage.getItem('application'),
            ),
          );
        } else if (type == 'put') {
          toast.success('Successfully updated.');
          history.push('/exception-management');
        } else {
          toast.success('Successfully added.');
          history.push('/exception-management');
        }
      })
      .catch((error) => {
        history.push('/exception-management');
      });
  };
};

const addExceptionManagementAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('incentive-override/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('incentive-override/' + id, { headers: headers });
  } else {
    result = axios.post('incentive-override', data, { headers: headers });
  }
  return result;
};
export const getExceptionTierEvals = (app, data) => {
  return (dispatch) => {
    getExceptionTierEvalsAPI(app, data).then((response) => {
      dispatch(getExceptionTierEvalsSuccess(response.data.response));
    });
  };
};

const getExceptionTierEvalsAPI = (app, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts/exception/tier-evals?application_type=' +
    app +
    '&contract_number=' +
    data,
    { headers: headers },
  );
  return result;
};
export const getExceptionTierEvalsSuccess = (data) => {
  return {
    type: EXCEPTION_MANAGEMENT_TIER_EVALS,
    data,
  };
};
//workflow anchors
export const getWorkFlowAnchors = (id) => {
  return (dispatch) => {
    getWorkFlowAnchorsAPI(id).then((response) => {
      dispatch(getWorkFlowAnchorsSuccess(response.data.response));
      sessionStorage.setItem(
        'functionalityDropdown',
        JSON.stringify(response.data.response),
      );
    });
  };
};

const getWorkFlowAnchorsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('workflow-anchors', { headers: headers });
  return result;
};

export const getWorkFlowAnchorsSuccess = (data) => {
  return {
    type: WORKFLOW_ANCHORS,
    data,
  };
};
export const getBatchJobStatus = (type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBatchJobStatusAPI(type)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getBatchJobStatusSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getBatchJobStatusAPI = (type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/batch-job-status?app_type=' + type, {
    headers: headers,
  });
  return result;
};

export const getBatchJobStatusSuccess = (data) => {
  return {
    type: BATCH_JOB_STATUS,
    data,
  };
};
export const getBatchJobStatusSecondPage = (type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBatchJobStatusSecondPageAPI(type, id)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getBatchJobStatusSecondPageSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getBatchJobStatusSecondPageAPI = (type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    '/batch-job-status-history?app_type=' + type + '&integration-id=' + id,
    { headers: headers },
  );
  return result;
};

export const getBatchJobStatusSecondPageSuccess = (data) => {
  return {
    type: BATCH_JOB_STATUS_SECOND_PAGE,
    data,
  };
};
export const getBatchJobStatusSingleData = (type, id, reportID) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getBatchJobStatusSingleDataAPI(type, id, reportID)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getBatchJobStatusSingleDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const getBatchJobStatusSingleDataAPI = (type, id, reportID) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (reportID) {
    result = axios.get(
      '/batch-job-status/' +
      id +
      '?app_type=' +
      type +
      '&report_id=' +
      reportID,
      { headers: headers },
    );
  } else {
    result = axios.get('/batch-job-status/' + id + '?app_type=' + type, {
      headers: headers,
    });
  }
  return result;
};

export const getBatchJobStatusSingleDataSuccess = (data) => {
  return {
    type: BATCH_JOB_STATUS_SINGLE_DATA,
    data,
  };
};
//product group

export const getProductGroupData = (pagination, limit, keyword) => {
  return (dispatch) => {
    getProductGroupDataApi(pagination, limit, keyword).then((response) => {
      dispatch(getProductGroupDataSuccess(response.data.response));
    });
  };
};

const getProductGroupDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('product-group?page=1&&limit=0', { headers: headers });
  return result;
};

export const getProductGroupDataSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_SUCCESS,
    data,
  };
};

export const createProductGroup = (data, type, id) => {
  return (dispatch) => {
    createProductGroupAPI(data, type, id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getProductGroupData(1, 10));

        history.push('/product-group');
      })
      .catch((error) => { });
  };
};

const createProductGroupAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'delete') {
    result = axios.delete('product-group/' + id, { headers: headers });
  } else if (type === 'edit') {
    result = axios.put('product-group/' + id, data, { headers: headers });
  } else {
    result = axios.post('product-group', data, { headers: headers });
  }
  return result;
};

export const getProductGroupSingleItem = (id) => {
  return (dispatch) => {
    getProductGroupSingleItemAPI(id).then((response) => {
      dispatch(getProductGroupSingleItemSuccess(response.data.response));
    });
  };
};
const getProductGroupSingleItemAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('product-group/' + id, { headers: headers });
  return result;
};

export const getProductGroupSingleItemSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_SINGLE_SUCCESS,
    data,
  };
};

//product-group-format

export const getProductGroupFormatData = () => {
  return (dispatch) => {
    getProductGroupFormatDataApi()
      .then((response) => {
        dispatch(getProductGroupFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getProductGroupFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=ProductGroupFormat', {
    headers: headers,
  });
  return result;
};

export const getProductGroupFormatDataSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_FORMAT_SUCCESS,
    data,
  };
};

export const getProductGroupFormatDataById = (id) => {
  return (dispatch) => {
    getProductGroupFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getProductGroupFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getProductGroupFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=ProductGroupFormat',
    { headers: headers },
  );
  return result;
};

export const getProductGroupFormatDataByIdSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_FORMAT_BY_ID_SUCCESS,
    data,
  };
};

//get membership format fields
export const getProductGroupFormatFields = () => {
  return (dispatch) => {
    getProductGroupFormatFieldsApi()
      .then((response) => {
        dispatch(getProductGroupFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getProductGroupFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=ProductGroupFormat',
    { headers: headers },
  );
  return result;
};

export const getProductGroupFormatFieldsSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_FORMAT_FIELDS_SUCCESS,
    data,
  };
};

// update membership format
export const updateProductGroupFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateProductGroupFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/product-group/product-group-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateProductGroupFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=ProductGroupFormat',
    data,
    { headers: headers },
  );
  return result;
};

//add membership format
export const addProductGroupFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addProductGroupFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/product-group/product-group-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addProductGroupFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=ProductGroupFormat',
    data,
    { headers: headers },
  );
  return result;
};

// delete membership format
export const deleteProductGroupFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteProductGroupFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success('Successfully deleted');
          dispatch(getProductGroupFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteProductGroupFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('upload-format/' + id, { headers: headers });
  return result;
};

// //upload File
export const uploadProductGroupFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadProductGroupFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadProductGroupSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        } else {
          dispatch(fetchEnd());
          //toast.error(response.data.complete_error_message);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const uploadProductGroupFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('product-group-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};

export const uploadProductGroupSuccess = (data) => {
  return {
    type: PRODUCT_GROUP_UPLOAD_SUCCESS,
    data,
  };
};

//get list customer master
export const getGeneralUOM = () => {
  return (dispatch) => {
    getGeneralUOMAPI()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.unique_uom
        )
          dispatch(getGeneralUOMSuccess(response.data.response.unique_uom));
      })
      .catch((error) => { });
  };
};

const getGeneralUOMAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('general-unique-uom', { headers: headers });

  return result;
};

export const getGeneralUOMSuccess = (data) => {
  return {
    type: GENERAL_UOM,
    data,
  };
};

//material Uom

export const getMaterialUoMData = () => {
  return (dispatch) => {
    getMaterialUoMDataApi().then((response) => {
      dispatch(materialUoMDataSuccess(response.data.response));
    });
  };
};
const getMaterialUoMDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('material-uom?limit=0', { headers: headers });
  return result;
};

export const materialUoMDataSuccess = (data) => {
  return {
    type: MATERIAL_UOM_SUCCESS,
    data,
  };
};

//currency conversion

export const getCurrencyConversionData = () => {
  return (dispatch) => {
    getCurrencyConversionDataApi()
      .then((response) => {
        dispatch(getCurrencyConversionDataSuccess(response.data));
      })
      .catch((error) => { });
  };
};

const getCurrencyConversionDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.get('currency-rates', { headers: headers });
  return result;
};

export const getCurrencyConversionDataSuccess = (data) => {
  return {
    type: CONVERSION_SUCCESS,
    data,
  };
};

// Contract XREF
//add contract xref
export const addContractXRefUser = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addContractXRefUserApi(data)
      .then((response) => {
        toast.success(response.data.response);
        history.push('/contract-xref');
        dispatch(getContractXRefData());
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());

        // //toast.error('Customer master record does not exist of the provided IMA End Customer ID');
      });
  };
};

const addContractXRefUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('xref-contract', data, { headers: headers });
  return result;
};
//edit contract xref
export const editContractXRefUser = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editContractXRefUserApi(data, id)
      .then((response) => {
        dispatch(fetchEnd());
        toast.success(response.data.response);
        history.push('/contract-xref');
        dispatch(getContractXRefData());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editContractXRefUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('xref-contract/' + id, data, { headers: headers });
  } else {
    result = axios.post('xref-contract', data, { headers: headers });
  }
  return result;
};
//delete contract xref
export const deleteContractXRefUser = (id) => {
  return (dispatch) => {
    deleteContractXRefUserApi(id)
      .then((response) => {
        toast.success('Successfully deleted.');
      })
      .catch((error) => { });
  };
};

const deleteContractXRefUserApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('xref-contract/' + id, { headers: headers });
  return result;
};
//get contract xref details
export const getContractXRefDetails = (id) => {
  return (dispatch) => {
    getContractXRefDetailsApi(id)
      .then((response) => {
        dispatch(getContractXRefDetailsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.error.message);
      });
  };
};

const getContractXRefDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-contract/' + id, { headers: headers });
  return result;
};

export const getContractXRefDetailsSuccess = (data) => {
  return {
    type: CONTRACT_XREF_DETAIL_SUCCESS,
    data,
  };
};
//get list contract xref
export const getContractXRefData = () => {
  return (dispatch) => {
    getContractXRefDataApi()
      .then((response) => {
        dispatch(getContractXrefDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const getContractXRefDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-contracts?page=1&&limit=0', { headers: headers });
  return result;
};

export const getContractXrefDataSuccess = (data) => {
  return {
    type: CONTRACT_XREF_SUCCESS,
    data,
  };
};

// integration mapping

export const getIntegrationMappingData = () => {
  return (dispatch) => {
    getIntegrationMappingDataApi()
      .then((response) => {
        dispatch(getIntegrationMappingDataSuccess(response.data.response));
      })
      .catch((error) => {
        ////toast.error(error.response.data.messgae);
      });
  };
};

const getIntegrationMappingDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'integrations-mapped?application_type=' +
    sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};

export const getIntegrationMappingDataSuccess = (data) => {
  return {
    type: INTEGRATION_MAPPING_SUCCESS,
    data,
  };
};

export const getIntegrationMappingDataById = (id) => {
  return (dispatch) => {
    getIntegrationMappingDataByIdApi(id)
      .then((response) => {
        dispatch(getIntegrationMappingDataByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const getIntegrationMappingDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'integration-mappings/' +
    id +
    '?application_type=' +
    sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};

export const getIntegrationMappingDataByIdSuccess = (data) => {
  return {
    type: INTEGRATION_MAPPING_BY_ID,
    data,
  };
};

export const createIntegrationMapping = (data, id, type) => {
  return (dispatch) => {
    createIntegrationMappingApi(data, id, type)
      .then((response) => {
        if (type == 'delete') {
          toast.success(response.data.response);
          dispatch(getIntegrationMappingData());
        } else {
          toast.success(response.data.message);
          if (response.data.savedRows) {
            if (type == 'edit')
              dispatch(
                getIntegrationMappingDataById(
                  response.data.savedRows['integration_id'],
                ),
              );
            else {
              console.log('test2');
              dispatch(
                getIntegrationMappingDataById(
                  response.data.savedRows[0].integration_id,
                ),
              );
            }

            //dispatch(createIntegrationMappingApiSuccess(response.data.savedRows))
          }
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.error.complete_error_message);
      });
  };
};

const createIntegrationMappingApi = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type == 'edit')
    result = axios.post(
      'integration-mapping?application_type=' +
      sessionStorage.getItem('application') +
      '&update_id=' +
      id,
      data,
      { headers: headers },
    );
  else if (type == 'delete')
    result = axios.delete(
      'integration-mapping/' +
      id +
      '?application_type=' +
      sessionStorage.getItem('application'),
      { headers: headers },
    );
  //for delete all mappings
  else
    result = axios.post(
      'integration-mapping?application_type=' +
      sessionStorage.getItem('application'),
      data,
      { headers: headers },
    );
  return result;
};
export const createIntegrationMappingApiSuccess = (data) => {
  return {
    type: MAPPING_CREATE_SUCCESS,
    data,
  };
};
//mapping fields
export const getIntegrationMappingFields = (actvity) => {
  return (dispatch) => {
    getIntegrationMappingFieldsApi(actvity)
      .then((response) => {
        dispatch(getIntegrationMappingFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        ////toast.error(error.response.data.messgae);
      });
  };
};

const getIntegrationMappingFieldsApi = (actvity) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('integration-map-fields?activity=' + actvity, {
    headers: headers,
  });
  return result;
};

export const getIntegrationMappingFieldsSuccess = (data) => {
  return {
    type: INTEGRATION_MAPPING_FIELDS,
    data,
  };
};

export const deleteIntegrationMapping = (id, typeId) => {
  return (dispatch) => {
    deleteIntegrationMappingApi(id)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(getIntegrationMappingDataById(typeId));
      })
      .catch((error) => {
        //toast.error(ServerError);
      });
  };
};

const deleteIntegrationMappingApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result = axios.delete('integration-mapping-ind/' + id, {
    headers: headers,
  });

  return result;
};
export const uploadIntegrationFile = (file, version) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadIntegrationFileApi(file, version)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(uploadIntegrationResponse(response.data));
        dispatch(getIntegrationMappingDataById(version));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data);
      });
  };
};

const uploadIntegrationFileApi = async (file, version) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  if (version === 'v2') {
    result = await axios.post('integration-mapping-upload/v2', file, {
      headers: headers,
    });
  } else {
    result = await axios.post('integration-mapping-upload', file, {
      headers: headers,
    });
  }
  return result;
};
export const uploadIntegrationResponse = (data) => {
  return {
    type: UPLOAD_INTEGRATION_FILE,
    data,
  };
};

//inttegrationFieldvalues
export const getIntegrationFieldValues = (option, type) => {
  return (dispatch) => {
    getIntegrationFieldValuesApi(option)
      .then((response) => {
        if (type == 'activityList')
          dispatch(getActivityListSuccess(response.data.response));
        else if (option == 'loadFrequencyValues') {
          dispatch(getLoadFrequencyListSuccess(response.data.response));
        } else dispatch(getDataSourceListSuccess(response.data.response));
      })
      .catch((error) => {
        ////toast.error(error.response.data.messgae);
      });
  };
};

const getIntegrationFieldValuesApi = (option) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('integration-field-value?option=' + option, {
    headers: headers,
  });
  return result;
};

export const getActivityListSuccess = (data) => {
  return {
    type: INTEGRATION_ACTIVITY,
    data,
  };
};
export const getLoadFrequencyListSuccess = (data) => {
  return {
    type: INTEGRATION_LOAD_FREQUENCY,
    data,
  };
};
export const getDataSourceListSuccess = (data) => {
  return {
    type: INTEGRATION_DATA_SOURCE,
    data,
  };
};

// Integration Studio Batch Job

export const addIntegrationBatchJobSetup = (data, edit, id) => {
  return (dispatch) => {
    addIntegrationBatchJobSetupAPI(data, edit, id)
      .then((response) => {
        history.push('/integration-batchjob-setup');
        if (edit === 'edit') toast.success(response.data.response);
        else if (edit === 'delete') {
          toast.success(response.data.response);
          dispatch(getBatchJobSetup());
        } else {
          toast.success(response.data.response);
        }
      })
      .catch((error) => { });
  };
};

const addIntegrationBatchJobSetupAPI = (data, edit, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (edit === 'edit')
    result = axios.put('batch-job/' + id, data, { headers: headers });
  else if (edit === 'delete')
    result = axios.delete('batch-job/' + id, data, { headers: headers });
  else result = axios.post('batch-job', data, { headers: headers });
  return result;
};

export const getIntegrationBatchJobSetup = () => {
  return (dispatch) => {
    getIntegrationBatchJobSetupAPI()
      .then((response) => {
        dispatch(getIntegrationBatchJobSetupSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getIntegrationBatchJobSetupAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('batch-job?app_type=Integration Studio', {
    headers: headers,
  });
  return result;
};

export const getIntegrationBatchJobSetupSuccess = (data) => {
  return {
    type: BATCH_INTEGRATION_JOB_ALL,
    data,
  };
};

export const getIntegrationBatchJobStatus = (id) => {
  return (dispatch) => {
    getIntegrationBatchJobStatusAPI(id)
      .then((response) => {
        dispatch(getIntegrationBatchJobStatusSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getIntegrationBatchJobStatusAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('/batch-job-status?app_type=Integration Studio', {
    headers: headers,
  });
  return result;
};

export const getIntegrationBatchJobStatusSuccess = (data) => {
  return {
    type: BATCH_INTEGRATION_JOB_STATUS,
    data,
  };
};
// cot xref
export const addCotXRefUser = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCotXRefUserApi(data)
      .then((response) => {
        toast.success('Successfully added.');
        dispatch(getCotXRefData());
        dispatch(fetchEnd());
        history.push('/cot-xref');
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addCotXRefUserApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('xref-classoftrade', data, { headers: headers });
  return result;
};
//edit cot xref
export const editCotXRefUser = (data, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    editCotXRefUserApi(data, id)
      .then((response) => {
        toast.success('Successfully updated.');
        history.push('/cot-xref');
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const editCotXRefUserApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (id) {
    result = axios.put('xref-classoftrade/' + id, data, { headers: headers });
  } else {
    result = axios.post('xref-classoftrade', data, { headers: headers });
  }
  return result;
};
//delete cot xref
export const deleteCotXRefUser = (id) => {
  return (dispatch) => {
    deleteCotXRefUserApi(id)
      .then((response) => {
        toast.success('Successfully deleted.');
        dispatch(getCotXRefData());
      })
      .catch((error) => { });
  };
};

const deleteCotXRefUserApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('xref-classoftrade/' + id, { headers: headers });
  return result;
};

//get cot xref detail view
export const getCotXRefDetails = (id) => {
  return (dispatch) => {
    getCotXRefDetailsApi(id)
      .then((response) => {
        dispatch(getCotXRefDetailsSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};
const getCotXRefDetailsApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('xref-classoftrade/' + id, { headers: headers });
  return result;
};

export const getCotXRefDetailsSuccess = (data) => {
  return {
    type: COT_XREF_VIEW_DETAIL_SUCCESS,
    data,
  };
};
// //get list of Cot xref data
export const getCotXRefData = (pagination, limit, keyword) => {
  return (dispatch) => {
    getCotXRefDataApi(pagination, limit, keyword)
      .then((response) => {
        dispatch(getCotXRefDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getCotXRefDataApi = (pagination, limit, keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (keyword) {
    result = axios.get('xref-classoftrade?keyword=' + keyword, {
      headers: headers,
    });
  } else {
    result = axios.get('xref-classoftrade?page=1&&limit=0', {
      headers: headers,
    });
  }
  return result;
};

export const getCotXRefDataSuccess = (data) => {
  return {
    type: COT_XREF_SUCCESS,
    data,
  };
};
//get list cot xref format
export const getCotXrefFormatData = () => {
  return (dispatch) => {
    getCotXrefFormatDataApi()
      .then((response) => {
        dispatch(getCotXrefFormatDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const getCotXrefFormatDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('upload-format?app-functionality=XrefClassOfTradeFormat', {
    headers: headers,
  });
  return result;
};

export const getCotXrefFormatDataSuccess = (data) => {
  return {
    type: COT_XREF_FORMAT_SUCCESS,
    data,
  };
};
// get cot xref format data by id
export const getCotXrefFormatDataById = (id) => {
  return (dispatch) => {
    getCotXrefFormatDataByIdApi(id)
      .then((response) => {
        dispatch(getCotXrefFormatDataByIdSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getCotXrefFormatDataByIdApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format/' + id + '?app-functionality=XrefClassOfTradeFormat',
    { headers: headers },
  );
  return result;
};

export const getCotXrefFormatDataByIdSuccess = (data) => {
  return {
    type: COT_XREF_BY_ID_SUCCESS,
    data,
  };
};
// get cot xref format fields
export const getCotXrefFormatFields = () => {
  return (dispatch) => {
    getCotXrefFormatFieldsApi()
      .then((response) => {
        dispatch(getCotXrefFormatFieldsSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const getCotXrefFormatFieldsApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'upload-format-fields?app-functionality=XrefClassOfTradeFormat',
    { headers: headers },
  );
  return result;
};

export const getCotXrefFormatFieldsSuccess = (data) => {
  return {
    type: COT_XREF_FIELDS_SUCCESS,
    data,
  };
};
// update cot xref
export const updateCotXrefFormatData = (id, data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    updateCotXrefFormatDataApi(id, data)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          history.push('/cot-xref/cot-xref-excel-upload');
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const updateCotXrefFormatDataApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put(
    'upload-format/' + id + '?app-functionality=XrefClassOfTradeFormat',
    data,
    { headers: headers },
  );
  return result;
};
//add cot xref format
export const addCotXrefFormatData = (data) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addCotXrefFormatDataApi(data)
      .then((response) => {
        toast.success(response.data.response);
        dispatch(fetchEnd());
        history.push('/cot-xref/cot-xref-excel-upload');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.error.message);
      });
  };
};

const addCotXrefFormatDataApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'upload-format?app-functionality=XrefClassOfTradeFormat',
    data,
    { headers: headers },
  );
  return result;
};
// delete cot xref format
export const deleteCotXrefFormatData = (id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    deleteCotXrefFormatDataApi(id)
      .then((response) => {
        dispatch(fetchEnd());
        if (response.status === 201) {
          toast.success(response.data.response);
          dispatch(getCotXrefFormatData());
        } else {
          //toast.error(ServerError);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(ServerError);
      });
  };
};

const deleteCotXrefFormatDataApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete(
    'upload-format/' + id + '?app-functionality=XrefClassOfTradeFormat',
    { headers: headers },
  );
  return result;
};
//upload File cot xref
export const uploadCotXrefFile = (id, file) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCotXrefFileApi(id, file)
      .then((response) => {
        if (response.status === 200) {
          dispatch(fetchEnd());
          dispatch(uploadCotXrefSuccess(response.data.response));
          if (
            response.data.response &&
            response.data.response.error_list == null
          ) {
            toast.success(response.data.response.success_msg);
          }
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.errorRows);
      });
  };
};

const uploadCotXrefFileApi = (id, file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result;
  result = axios.post('/xref-classoftrade-upload?format_id=' + id, file, {
    headers: headers,
  });
  return result;
};
export const uploadCotXrefSuccess = (data) => {
  return {
    type: COT_XREF_UPLOAD_SUCCESS,
    data,
  };
};

// membership update
export const updateMembershipData = () => {
  return (dispatch) => {
    updateMembershipDataApi()
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => { });
  };
};
const updateMembershipDataApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('membership-update', { headers: headers });
  return result;
};
// Membership Contract XREF
export const getMembershipContractXref = (pagination, limit) => {
  return (dispatch) => {
    getMembershipContractXrefAPI(pagination, limit)
      .then((response) => {
        dispatch(getMembershipContractXrefSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getMembershipContractXrefAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'membership-contract?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getMembershipContractXrefSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_XREF_SUCCESS,
    data,
  };
};
export const getMembershipContractXrefById = (id) => {
  return (dispatch) => {
    getMembershipContractXrefByIdAPI(id)
      .then((response) => {
        dispatch(getMembershipContractXrefByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getMembershipContractXrefByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('membership-contract/' + id, { headers: headers });
  return result;
};
export const getMembershipContractXrefByIdSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_XREF_BY_ID,
    data,
  };
};
export const createMembershipContractXref = (data, id, type) => {
  return (dispatch) => {
    createMembershipContractXrefAPI(data, id, type)
      .then((response) => {
        if (type === 'edit') {
          toast.success('Membership Contract Updated Successfully');
          history.push('/membership-contract');
          dispatch(getMembershipContractXref(1, 0));
        } else if (type === 'delete') {
          toast.success(response.data.response);
          history.push('/membership-contract');
          dispatch(getMembershipContractXref(1, 0));
        } else {
          toast.success('Membership Contract Created Successfully');
          history.push('/membership-contract');
          dispatch(getMembershipContractXref(1, 0));
        }
      })
      .catch((error) => { });
  };
};
const createMembershipContractXrefAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('membership-contract/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('membership-contract/' + id, { headers: headers });
  } else {
    result = axios.post('membership-contract', data, { headers: headers });
  }
  return result;
};
//Membership Contract Group
export const getMembershipContractGroup = (pagination, limit) => {
  return (dispatch) => {
    getMembershipContractGroupAPI(pagination, limit)
      .then((response) => {
        dispatch(getMembershipContractGroupSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getMembershipContractGroupAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'membership-contract-group?page=' + pagination + '&limit=' + limit,
    { headers: headers },
  );
  return result;
};
export const getMembershipContractGroupSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_SUCCESS,
    data,
  };
};
export const getMembershipContractGroupById = (id) => {
  return (dispatch) => {
    getMembershipContractGroupByIdAPI(id)
      .then((response) => {
        dispatch(getMembershipContractGroupByIdSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error(ServerError)
      });
  };
};
const getMembershipContractGroupByIdAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('membership-contract-group/' + id, { headers: headers });
  return result;
};
export const getMembershipContractGroupByIdSuccess = (data) => {
  return {
    type: MEMBERSHIP_CONTRACT_GROUP_BY_ID,
    data,
  };
};
export const createMembershipContractGroup = (data, id, type) => {
  return (dispatch) => {
    createMembershipContractGroupAPI(data, id, type)
      .then((response) => {
        if (type === 'edit') {
          toast.success('Membership Contract Group Updated Successfully');
          history.push('/membership-contract-group');
          dispatch(getMembershipContractGroup(1, 0));
        } else if (type === 'delete') {
          toast.success(response.data.response);
          history.push('/membership-contract-group');
          dispatch(getMembershipContractGroup(1, 0));
        } else {
          toast.success('Membership Contract Group Created Successfully');
          history.push('/membership-contract-group');
          dispatch(getMembershipContractGroup(1, 0));
        }
      })
      .catch((error) => { });
  };
};
const createMembershipContractGroupAPI = (data, id, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'edit') {
    result = axios.put('membership-contract-group/' + id, data, {
      headers: headers,
    });
  } else if (type === 'delete') {
    result = axios.delete('membership-contract-group/' + id, {
      headers: headers,
    });
  } else {
    result = axios.post('membership-contract-group', data, {
      headers: headers,
    });
  }
  return result;
};
//get GPO Address Search data
export const getGPOAddressSearchData = (pagination, limit, data, type) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getGPOAddressSearchDataApi(pagination, limit, data, type).then(
      (response) => {
        dispatch(fetchEnd());
        dispatch(getGPOAddressSearchDataSuccess(response.data.response));
        history.push('/gpo-address-search-result');
      },
    );
  };
};

const getGPOAddressSearchDataApi = (pagination, limit, data, type) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ',' ||
      data[propName] === 'Invalid date'
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'static') {
    result = axios.get(
      'membership-gporoster-static?page=1&&limit=0',
      { params: data },
      { headers: headers },
    );
  } else {
    result = axios.get(
      'membership-gporoster?page=1&&limit=0',
      { params: data },
      { headers: headers },
    );
  }
  return result;
};

export const getGPOAddressSearchDataSuccess = (data) => {
  return {
    type: GPO_ADDRESS_SEARCH_SUCCESS,
    data,
  };
};
// get Tire activation Address Search
export const getTierActivationAddressSearchData = (
  pagination,
  limit,
  data,
  type,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getTierActivationAddressSearchDataApi(pagination, limit, data, type).then(
      (response) => {
        dispatch(fetchEnd());
        dispatch(
          getTierActivationAddressSearchDataSuccess(response.data.response),
        );
        history.push('/tier-address-search-result');
      },
    );
  };
};

const getTierActivationAddressSearchDataApi = (
  pagination,
  limit,
  data,
  type,
) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'static') {
    result = axios.get(
      'membership-tieractivation-static?page=1&&limit=0',
      { params: data },
      { headers: headers },
    );
  } else {
    result = axios.get(
      'membership-tieractivation?page=1&&limit=0',
      { params: data },
      { headers: headers },
    );
  }
  return result;
};

export const getTierActivationAddressSearchDataSuccess = (data) => {
  return {
    type: TIER_ACTI_ADDRESS_SEARCH_SUCCESS,
    data,
  };
};
export const massUpdateMembership = (data) => {
  return (dispatch) => {
    massUpdateMembershipAPI(data)
      .then((response) => {
        dispatch(massUpdateMembershipSuccess(response.data.response));
        history.push('/mass-membership-update-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};
const massUpdateMembershipAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('membership-mass/fetch', data, { headers: headers });
  return result;
};
export const massUpdateMembershipSuccess = (data) => {
  return {
    type: MASS_MEMBERSHIP_UPDATE,
    data,
  };
};
export const massUpdateMembershipAction = (data) => {
  return (dispatch) => {
    massUpdateMembershipActionAPI(data)
      .then((response) => {
        dispatch(massUpdateMembershipActionSuccess(response.data.response));
        toast.success(response.data.message);
        history.push('/mass-membership-update');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};
const massUpdateMembershipActionAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('membership-mass/update', data, {
    headers: headers,
  });
  return result;
};
export const massUpdateMembershipActionSuccess = (data) => {
  return {
    type: MASS_MEBERSHIP_UPDATE_ACTION,
    data,
  };
};
export const addWorkFlowAssignments = (data, type, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    addWorkFlowAssignmentsAPI(data, type, id)
      .then((response) => {
        dispatch(fetchEnd());

        if (type === 'delete') {
          dispatch(getWorkFlowAssignments(1, 0));
          toast.success(response.data.response);
        } else {
          history.push('/workflow-assignment');
          toast.success(response.data.response);
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const addWorkFlowAssignmentsAPI = (data, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (type === 'put') {
    result = axios.put('workflow-assignment/' + id, data, { headers: headers });
  } else if (type === 'delete') {
    result = axios.delete('workflow-assignment/' + id, data, {
      headers: headers,
    });
  } else {
    result = axios.post('workflow-assignment', data, { headers: headers });
  }
  return result;
};
export const getWorkFlowAssignments = (pagination, limit) => {
  return (dispatch) => {
    getWorkFlowAssignmentsAPI(pagination, limit).then((response) => {
      dispatch(getWorkFlowAssignmentsSuccess(response.data.response));
    });
  };
};

const getWorkFlowAssignmentsAPI = (pagination, limit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'workflow-assignment?page=' + pagination + '&limit=' + limit,
    {
      headers: headers,
    },
  );
  return result;
};

export const getWorkFlowAssignmentsSuccess = (data) => {
  return {
    type: WORK_FLOW_ASSIGNMENTS_SUCCESS,
    data,
  };
};
export const getWorkFlowAssignmentsDetails = (id) => {
  return (dispatch) => {
    getWorkFlowAssignmentsDetailsAPI(id).then((response) => {
      dispatch(getWorkFlowAssignmentsDetailsSuccess(response.data.response));
    });
  };
};

const getWorkFlowAssignmentsDetailsAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('workflow-assignment/' + id, { headers: headers });
  return result;
};

export const getWorkFlowAssignmentsDetailsSuccess = (data) => {
  return {
    type: WORK_FLOW_ASSIGNMENTS_DETAIL_SUCCESS,
    data,
  };
};
