import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from '../src/redux/store';
import { Provider } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";
function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
);
