import { getEnvVarValue } from 'cra-envs';

export const envNames = [
  'BASE_URL',
  'OKTA_DOMAIN',
  'OKTA_CLIENT_ID',
  'OKTA_REDIRECT_URI',
  'OKTA_CLIENT_SECRET',
  'PROD_SIGN_IN'
];
let env = undefined;
export function getEnv() {
  if (env === undefined) {
    env = {};
    for (const envName of envNames) {
      env[envName] = getEnvVarValue(envName);
    }
  }
  return env;
}
