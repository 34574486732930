import {
  ADD_ELIGIBILITY_RULE,
  ADD_ELIGIBILITY_RULE_FAILED,
  ADD_ELIGIBILITY_RULE_SUCCESS,
  ADD_CALCULATION_RULE_FAILED,
  ADD_CALCULATION_RULE,
  ADD_CONTRACT_GENERAL_DATA,
  CHANGED_CONTRACT_DETAIL_SUCCESS,
  CONTRACT_DATA,
  CONTRACT_DATA_COMPLETE,
  CONTRACT_DETAIL_DATA,
  CALCULATION_RULE_SUCCESS,
  CONTRACT_NUMBER,
  FETCH_START,
  FETCH_END,
  FETCH_START_UPLOAD,
  FETCH_END_UPLOAD,
  UPLOAD_ATTACHMENT_SUCCESS,
  SHOW_CONTRACT_POPUP,
  CONTRACT_DATA_COMPARE,
  UPDATED_CONTRACT_LIST,
  CONTRACT_CHANGE_LOG,
  CONTRACT_ALL_APPTYPE,
  CONTRACT_DATA_LIST,
  CONTRACT_RULES_DATA_API_SUCCESS,
  CONTRACT_DATA_LIST_DASHBOARD,
  UPDATED_CONTRACT_ALL_LIST,
  FP_HISTORY_SUCCESS,
  MASS_CONTRACT_UPDATE,
  MASS_CONTRACT_UPDATE_ACTION,
  UPLOAD_CALCULATION_RULES_SUCCESS,
  APPROVAL_LEVEL_DATA,
  EXPIRY_DATA,
  CALCU_RULE_VALID_MSG,
} from './ActionTypes';
import axios from '../../../../axios.conf';
import { toast } from 'react-toastify';
import { ServerError } from '../../../../library/constants';
import { history } from '../../../../components/Helpers';
import secureLocalStorage from 'react-secure-storage';
import { getLockData, updateLockedPage } from '../../Auth/Lock/AuthAction';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchEnd = () => {
  return {
    type: FETCH_END,
  };
};

export const addEligibilityRulesSuccess = (data) => {
  return {
    type: ADD_ELIGIBILITY_RULE,
    data,
  };
};

export const eligibilityRulesAdded = () => {
  return {
    type: ADD_ELIGIBILITY_RULE_SUCCESS,
  };
};

export const addEligibilityRulesFailed = (data) => {
  return {
    type: ADD_ELIGIBILITY_RULE_FAILED,
    data,
  };
};

export const addCalculationRuleFailed = (data) => {
  return {
    type: ADD_CALCULATION_RULE_FAILED,
    data,
  };
};

export const calculationRuleSuccess = () => {
  return {
    type: CALCULATION_RULE_SUCCESS,
  };
};

export const getContractsTableDataSuccess = (data) => {
  return {
    type: CONTRACT_DATA,
    data,
  };
};

export const getContractsTableCompleteDataSuccess = (data) => {
  return {
    type: CONTRACT_DATA_COMPLETE,
    data,
  };
};

export const getContractsDetailDataSuccess = (data) => {
  return {
    type: CONTRACT_DETAIL_DATA,
    data,
  };
};

export const addEligibilityRules = (data) => {
  return (dispatch) => {
    getAddElligibityRulesApi(data)
      .then((response) => {
        toast.success('Eligiility Rules added !');
        dispatch(getEligibilityRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addEligibilityRulesFailed(error));
      });
  };
};

export const editEligibilityRules = (data, id) => {
  return (dispatch) => {
    getEditEligibilityRulesApi(data, id)
      .then((response) => {
        toast.success('Eligibility rules have been updated successfully!');
        dispatch(getEligibilityRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addEligibilityRulesFailed(error));
      });
  };
};

export const getContractsTableData = (
  pagination,
  limit,
  approval,
  changeContract,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractsTableApi(pagination, limit, approval, changeContract)
      .then((response) => {
        dispatch(
          getContractsTableDataComplete(response.data.response.total_record),
        );
        dispatch(fetchEnd());
        dispatch(getContractsTableDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.complete_error_message
        // ) {
        //   toast.warn(error.response.data.complete_error_message);
        // }
      });
  };
};
//complete contract data starts here
export const getContractsTableDataComplete = (tableLimit) => {
  return (dispatch) => {
    getContractsCompleteTableCompleteApi(tableLimit)
      .then((response) => {
        dispatch(getContractsTableCompleteDataSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const getContractsCompleteTableCompleteApi = (tableLimit) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts?limit=' +
    tableLimit +
    '&application_type=' +
    sessionStorage.getItem('application'),
    { headers: headers },
  );
  return result;
};

export const getContractDetailsData = (id, status, type) => {
  return (dispatch) => {
    if (type == 'edit') {
      var data = {
        functionality: 'contract_header',
        functionality_id: id,
      };
      getLockData(data)
        .then((response) => {
          if (response.data.response == true) {
            data['path'] = '/contract-setup/edit-contract/' + id;
            dispatch(updateLockedPage(data));
            dispatch(fetchStart());
            getContractsDetailApi(id)
              .then((response) => {
                dispatch(fetchEnd());
                dispatch(addContractGeneralDataSuccess(response.data.response));
                dispatch(
                  contractNumber(response.data.response.contract_number),
                );
                if (status === 'submit_approval') {
                  history.push({
                    pathname:
                      '/contract-setup/edit-contract-submit-approval/' + id,
                    state: 'editContract',
                    id: id,
                  });
                } else {
                  history.push({
                    pathname: '/contract-setup/edit-contract/' + id,
                    state: 'editContract',
                    id: id,
                  });
                }
              })
              .catch((error) => {
                dispatch(fetchEnd());
              });
          } else {
            toast.warn(
              'This is something that is being worked on at the moment.Please try again later !!',
            );
          }
        })
        .catch((error) => { });
    } else {
      dispatch(fetchStart());
      getContractsDetailApi(id)
        .then((response) => {
          dispatch(fetchEnd());
          dispatch(addContractGeneralDataSuccess(response.data.response));
          dispatch(contractNumber(response.data.response.contract_number));
        })
        .catch((error) => {
          dispatch(fetchEnd());
          //toast.error("Something went wrong.");
        });
    }
  };
};

export const getContractViewData = (id, type, newTab) => {
  return (dispatch) => {
    getContractsDetailApi(id)
      .then((response) => {
        if (type != 'massUpdate') {
          if (newTab) {
            localStorage.setItem('loginData', sessionStorage.getItem('loginData'));
            localStorage.setItem('application', sessionStorage.getItem('application'));
            window.open(
              '/contract-setup/view-contract/' + id,
              '_blank',
              'noopener noreferrer',
            );
          }
          else {
            history.push('/contract-setup/view-contract/' + id);
          }
        }
        dispatch(fetchEnd());
        dispatch(addContractGeneralDataSuccess(response.data.response));
        dispatch(contractNumber(response.data.response.contract_number));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

export const getEligibilityRulesTableData = (id) => {
  return (dispatch) => {
    getAddElligibityRulesTableApi()
      .then((response) => {
        dispatch(addEligibilityRulesSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(addEligibilityRulesSuccess([]));
      });
  };
};

export const getCalculationRulesTableData = () => {
  return (dispatch) => {
    getAddCalculationRulesTableApi()
      .then((response) => {
        dispatch(addCalculationRulesSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(addCalculationRulesSuccess([]));
      });
  };
};

export const addCalculationRulesSuccess = (data) => {
  return {
    type: ADD_CALCULATION_RULE,
    data,
  };
};

export const addCalculationRules = (data) => {
  return (dispatch) => {
    getAddCalculationRulesApi(data)
      .then((response) => {
        toast.success('Calculation rules have been added successfully!');
        dispatch(getCalculationRulesTableData());
        dispatch(calculationRuleSuccess());
      })
      .catch((error) => {
        dispatch(addCalculationRuleFailed(error));
        //toast.error("Something went wrong.");
      });
  };
};

export const editCalculationRules = (data, id) => {
  return (dispatch) => {
    getEditCalculationRulesApi(data, id)
      .then((response) => {
        toast.success('Calculation rules have been updated successfully!');
        dispatch(getCalculationRulesTableData());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
        dispatch(addCalculationRuleFailed(error));
      });
  };
};

export const addContractGeneralDataSuccess = (data) => {
  return {
    type: ADD_CONTRACT_GENERAL_DATA,
    data,
  };
};

export const contractNumber = (data) => {
  return {
    type: CONTRACT_NUMBER,
    data,
  };
};

export const addContractGeneralData = (data, forced, id) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getAddContractApi(data, forced, id)
      .then((response) => {
        dispatch(fetchEnd());
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.condition_id_message
        ) {
          toast.warn(response.data.response.condition_id_message);
        }
        if (
          response.data.response.attribute_message &&
          response.data.response.attribute_message.length > 0
        ) {
          // toast.warn(response.data.response.attribute_message)
        }
        if (response.status === 208) {
          dispatch(showContractOverlapPopup(true));
        } else {
          dispatch(
            addContractGeneralDataSuccess(response.data.response.contract),
          );
          sessionStorage.removeItem('functionality-name');
          toast.success(
            'Contract ' +
            response.data.response.contract.contract_number +
            ' Saved',
          );
          history.push('/contract-setup');
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

export const resetShowContractOverlapPopup = () => {
  return (dispatch) => {
    dispatch(showContractOverlapPopup(false));
  };
};

export const showContractOverlapPopup = (data) => {
  return {
    type: SHOW_CONTRACT_POPUP,
    data,
  };
};

const getAddContractApi = (data, forced, id) => {
  for (var propName in data) {
    if (data[propName] === '' || data[propName] === null) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  var currenId = id;
  if (data === 0) {
    result = axios.post('contracts', { headers: headers });
  } else {
    if (currenId) {
      result = axios.post('contracts/' + currenId, data, { headers: headers });
    } else {
      if (forced === true) {
        result = axios.post('contracts?force=true', data, { headers: headers });
      } else {
        result = axios.post(
          'contracts?application_type=' + sessionStorage.getItem('application'),
          data,
          { headers: headers },
        );
      }
    }
  }
  return result;
};

const getAddElligibityRulesApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'contracts/' +
    localStorage.getItem('currentAddContractID') +
    '/eligibility_rules',
    data,
    { headers: headers },
  );
  return result;
};

const getEditEligibilityRulesApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('contract_eligibility_rules/' + id, data, {
    headers: headers,
  });
  return result;
};

const getEditCalculationRulesApi = (data, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.put('contract_calculation_rules/' + id, data, {
    headers: headers,
  });
  return result;
};

const getAddElligibityRulesTableApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts/' +
    localStorage.getItem('currentAddContractID') +
    '/eligibility_rules',
    { headers: headers },
  );
  return result;
};

const getAddCalculationRulesApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'contracts/' +
    localStorage.getItem('currentAddContractID') +
    '/calculation_rules',
    data,
    { headers: headers },
  );
  return result;
};

const getAddCalculationRulesTableApi = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts/' +
    localStorage.getItem('currentAddContractID') +
    '/calculation_rules',
    { headers: headers },
  );
  return result;
};

const getContractsTableApi = (pagination, limit, approval, contractChange) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (contractChange === 'No Change Contract') {
    if (approval === 'submit') {
      result = axios.get(
        'contracts?is_update_approval_contract=false&approval_status=submit_approval&page=' +
        pagination +
        '&&limit=' +
        limit +
        '&application_type=' +
        sessionStorage.getItem('application'),
        { headers: headers },
      );
    } else {
      result = axios.get(
        'contracts?is_update_approval_contract=false&page=' +
        pagination +
        '&&limit=' +
        limit +
        '&application_type=' +
        sessionStorage.getItem('application'),
        { headers: headers },
      );
    }
  } else {
    if (approval === 'submit') {
      result = axios.get(
        'contracts?approval_status=submit_approval' + //&page=" +
        // pagination +
        '&&limit=' +
        limit +
        '&application_type=' +
        sessionStorage.getItem('application'),
        { headers: headers },
      );
    } else {
      result = axios.get(
        'contracts?page=' +
        pagination +
        '&&limit=' +
        limit +
        '&application_type=' +
        sessionStorage.getItem('application'),
        { headers: headers },
      );
    }
  }
  return result;
};

const getContractsDetailApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contracts/' + id, { headers: headers });
  return result;
};

export const cloneContract = (id, data) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getCloneContractApi(id, data)
      .then((response) => {
        toast.success('Contract clonned successfully.');
        dispatch(getContractsTableDataList());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const getCloneContractApi = (id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'contracts/' +
    id +
    '/clone?application_type=' +
    sessionStorage.getItem('application'),
    data,
    { headers: headers },
  );
  return result;
};

//delete contract
export const deleteContract = (id) => {
  const pagination = 1;
  const limit = 10;
  return (dispatch) => {
    getDeleteContractApi(id)
      .then((response) => {
        toast.success('Deleted successfully.');
        dispatch(getContractsTableDataList());
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

//change request details
export const changeRequestDetails = (id, updatedId, type) => {
  return (dispatch) => {
    getContractsDetailApi(id)
      .then((response) => {
        dispatch(getChangedContractDetailsSuccess(response.data.response));
        if (type === 'compare') {
          history.push('/approval-reject/change-request/' + updatedId);
        } else {
          history.push('/contract-setup/change-request-detail/' + id);
        }
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};
export const getChangedContractDetailsSuccess = (data) => {
  return {
    type: CHANGED_CONTRACT_DETAIL_SUCCESS,
    data,
  };
};

const getDeleteContractApi = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.delete('contracts/' + id, { headers: headers });
  return result;
};

//put contract
export const putContractGeneralData = (
  data,
  status,
  newApi,
  state,
  currenId,
) => {
  console.log(currenId);
  console.log('edit');
  return (dispatch) => {
    dispatch(fetchStart());
    putAddContractApi(data, status, currenId)
      .then((response) => {
        dispatch(fetchEnd());
        sessionStorage.removeItem('functionality-name');
        if (status == 'contract-approval') {
          dispatch(getContractsTableData(1, 10, 'submit'));
          history.push('/approval-reject');
        } else if (status == 'CR') {
          toast.success(
            'Contract ' +
            response.data.response.updated_contract.contract_number +
            ' Saved',
          );
          if (
            response.data.response.condition_id_message &&
            response.data.response.condition_id_message.length > 0
          ) {
            toast.warn(response.data.response.condition_id_message);
          }
          if (
            response.data.response.attribute_message &&
            response.data.response.attribute_message.length > 0
          ) {
            // toast.warn(response.data.response.attribute_message)
          }
          history.push('/contract-setup');
        } else {
          if (state === 'searchByContract') {
            toast.success(
              'Contract ' +
              response.data.response.contract.contract_number +
              ' Saved',
            );
            history.push({
              pathname: '/contract-setup',
              state: 'searchByContract',
            });
            if (
              response.data.response.condition_id_message &&
              response.data.response.condition_id_message.length > 0
            ) {
              toast.warn(response.data.response.condition_id_message);
            }
            if (
              response.data.response.attribute_message &&
              response.data.response.attribute_message.length > 0
            ) {
              // toast.warn(response.data.response.attribute_message)
            }
          } else {
            toast.success(
              'Contract ' +
              response.data.response.contract.contract_number +
              ' Saved',
            );
            history.push('/contract-setup');
            if (
              response.data.response.condition_id_message &&
              response.data.response.condition_id_message.length > 0
            ) {
              toast.warn(response.data.response.condition_id_message);
            }
            if (
              response.data.response.attribute_message &&
              response.data.response.attribute_message.length > 0
            ) {
              // toast.warn(response.data.response.attribute_message)
            }
          }
        }

        if (
          response.data &&
          response.data.response &&
          response.data.response.complete_error_message &&
          response.data.response.complete_error_message
        )
          if (
            response.data &&
            response.data.response &&
            response.data.response.date_message
          ) {
            //toast.error(response.data.response.complete_error_message);
            toast.warn(response.data.response.date_message);
          }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const putAddContractApi = (data, status, currenId) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  //var currenId = //localStorage.getItem("currentAddContractID");
  if (status === 'CR') {
    result = axios.post(
      'contracts/' +
      currenId +
      '/update-request?application_type=' +
      sessionStorage.getItem('application'),
      data,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'contracts/' +
      currenId +
      '?application_type=' +
      sessionStorage.getItem('application'),
      data,
      { headers: headers },
    );
  }
  return result;
};

//put contract
export const newAPIForAccRe = (status, bulk, id, data, state) => {
  return (dispatch) => {
    newAPIForAccReAPI(status, bulk, id, data)
      .then((response) => {
        if (bulk === 'bulk') {
          dispatch(getContractsTableData(1, 10, 'submit'));
        } else {
        }
        if (state === 'searchByContract') {
          history.push({
            pathname: '/contract-setup',
            state: 'searchByContract',
          });
        } else {
          history.push('/contract-setup');
        }
      })
      .catch((error) => { });
  };
};

const newAPIForAccReAPI = (status, bulk, id, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (bulk === 'bulk') {
    result = axios.post(
      'contracts/' +
      id +
      '/update-request/' +
      status +
      '?application_type=' +
      sessionStorage.getItem('application'),
      { headers: headers },
    );
  } else {
    result = axios.post(
      'contracts/' +
      id +
      // localStorage.getItem("currentAddContractID") +
      '/update-request/' +
      status +
      '?application_type=' +
      sessionStorage.getItem('application'),
      data,
      { headers: headers },
    );
  }
  return result;
};
//search starts here
export const searchContracts = (keyword) => {
  return (dispatch) => {
    if (keyword) {
      getSearchApi(keyword)
        .then((response) => {
          dispatch(getContractsTableDataSuccess(response.data.response));
        })
        .catch((error) => { });
    } else {
      dispatch(getContractsTableData(0, 10));
    }
  };
};

const getSearchApi = (keyword) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contracts?keyword=' + keyword, { headers: headers });
  return result;
};
//search ends here

//upload attachment
export const uploadAttachment = (file) => {
  return (dispatch) => {
    dispatch(fetchStartUpload());
    dispatch(fetchStart());
    uploadAttachmentApi(file)
      .then((response) => {
        dispatch(fetchEndUpload());
        dispatch(fetchEnd());
        dispatch(uploadAttachmentSuccess(response.data.response.locate_url));
        // if (localStorage.getItem("currentAddContractID"))
        //   dispatch(
        //     getContractDetailsData(localStorage.getItem("currentAddContractID"))
        //   );
      })
      .catch((error) => {
        dispatch(fetchEnd());
        dispatch(fetchEndUpload());
        //toast.error("File Uploading Error.");
      });
  };
};

export const fetchStartUpload = () => {
  return {
    type: FETCH_START_UPLOAD,
  };
};

export const fetchEndUpload = () => {
  return {
    type: FETCH_END_UPLOAD,
  };
};

const uploadAttachmentApi = (file) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post('file-upload', file, { headers: headers });
  return result;
};

export const uploadAttachmentSuccess = (data) => {
  return {
    type: UPLOAD_ATTACHMENT_SUCCESS,
    data,
  };
};
//notes file download
export const downloadNotesAttachment = (data) => {
  return (dispatch) => {
    dispatch(fetchStartUpload());
    downloadNotesAttachmentApi(data)
      .then((response) => {
        dispatch(fetchEndUpload());
        let [data, data2] = response.headers['content-type'].split(';');
        toast('Please wait. File is getting downloaded.');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let date = Date.now();
        link.setAttribute('download', data2);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        dispatch(fetchEndUpload());
      });
  };
};
const downloadNotesAttachmentApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  let result;
  result = axios.post('file-download', data, {
    headers: headers,
    responseType: 'blob',
  });
  return result;
};
//batch approval
export const batchApprove = (data, status, dataApproved, comment, type) => {
  return (dispatch) => {
    batchApproveApi(data, status, dataApproved, comment, type)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.response &&
          response.data.response.message
        ) {
          toast.success(response.data.response.message);
        }
        sessionStorage.setItem('ApproveContract1', false);
        dispatch(getContractsTableData(1, 0, 'submit'));
      })
      .catch((error) => {
        sessionStorage.setItem('ApproveContract1', false);
      });
  };
};

const batchApproveApi = (data, status, dataApproved, comment, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var formData = {
    contract_ids: data,
    approval_status: status,
    update_contract_ids: dataApproved,
    notes: comment,
  };
  var result;
  if (type == 'Issue Resolution') {
    formData['contract_status'] = 'Issue Resolution';
    result = axios.post(
      'approval-request/bulk-approval/contract?application_type=' +
      sessionStorage.getItem('application'),
      formData,
      { headers: headers },
    );
  } else {
    result = axios.post(
      'approval-request/bulk-approval/contract?application_type=' +
      sessionStorage.getItem('application'),
      formData,
      { headers: headers },
    );
  }
  return result;
};

//data for compare contract
export const getContractDetailsDataCompare = (id) => {
  return (dispatch) => {
    getContractDetailsDataCompareAPI(id)
      .then((response) => {
        dispatch(getContractDetailsDataCompareSuccess(response.data.response));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

export const getContractDetailsDataCompareSuccess = (data) => {
  return {
    type: CONTRACT_DATA_COMPARE,
    data,
  };
};

const getContractDetailsDataCompareAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contracts/' + id, { headers: headers });
  return result;
};

//get updated contracts list
export const updatedContractsList = (data) => {
  return (dispatch) => {
    updatedContractsListAPI(data).then((response) => {
      dispatch(updatedContractsListSuccess(response.data.response));
    });
  };
};

export const updatedContractsListSuccess = (data) => {
  return {
    type: UPDATED_CONTRACT_LIST,
    data,
  };
};

const updatedContractsListAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts?update_contract_id.in=' +
    data.toString() +
    '&application_type=' +
    sessionStorage.getItem('application') +
    '&limit=0&update_request_closed=false&is_update_approval_contract=true',
    { headers: headers },
  );
  return result;
};

// change log
export const getContractChangeLog = (id) => {
  return (dispatch) => {
    getContractChangeLogAPI(id).then((response) => {
      dispatch(getContractChangeLogAPISuccess(response.data.response));
    });
  };
};

const getContractChangeLogAPI = (id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contract-change-log?contract_number=' + id, {
    headers: headers,
  });
  return result;
};

export const getContractChangeLogAPISuccess = (data) => {
  return {
    type: CONTRACT_CHANGE_LOG,
    data,
  };
};

//contracts by application type
export const getContractsTableDataByApplicationType = (appType) => {
  return (dispatch) => {
    getContractsTableDataByApplicationTypeAPI(appType)
      .then((response) => {
        dispatch(
          getContractsTableDataByApplicationTypeSuccess(response.data.response),
        );
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};

const getContractsTableDataByApplicationTypeAPI = (appType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contracts?application_type=' +
    appType +
    '&limit=0&is_update_approval_contract=false',
    { headers: headers },
  );
  return result;
};

export const getContractsTableDataByApplicationTypeSuccess = (data) => {
  return {
    type: CONTRACT_ALL_APPTYPE,
    data,
  };
};

//Contract Rules Data

export const getContractRulesData = (data, table) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractRulesDataApi(data, table).then((response) => {
      dispatch(fetchEnd());
      if (response.data.response && response.data.response.length > 0) {
        dispatch(getContractRulesDataApiSuccess(response.data.response));
      }
    });
  };
};
const getContractRulesDataApi = (data, table) => {
  for (var propName in data) {
    if (
      data[propName] === '' ||
      data[propName] === null ||
      data[propName] === undefined ||
      data[propName].length === 0 ||
      data[propName] === '1970-01-01T00:00:00.000Z,1970-01-01T00:00:00.000Z' ||
      data[propName] === '1970-01-01T00:00:00.000Z' ||
      data[propName] === ','
    ) {
      delete data[propName];
    }
  }
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('contract-search-rule/table?table_name=' + table, data, {
    headers: headers,
  });
  return result;
};

export const getContractRulesDataApiSuccess = (data) => {
  return {
    type: CONTRACT_RULES_DATA_API_SUCCESS,
    data,
  };
};

// get contract by search Eligibility Rules
export const getContractsTableDataBySearchEligibilty = (data, page) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractsTableDataBySearchEligibiltyAPI(data, page)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getContractsTableDataListSuccess(response.data.response));
        if (page === 'List') {
          if (
            response.data.response &&
            response.data.response.contracts &&
            response.data.response.contracts.length > 0
          ) {
            history.push({
              pathname: '/contract-setup-list',
              state: 'searchByContract',
            });
          } else {
            toast.error('No records found.');
          }
        } else {
          history.push({
            pathname: '/contract-setup',
            state: 'searchByContract',
          });
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};
const getContractsTableDataBySearchEligibiltyAPI = (data, page) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'List') {
    result = axios.post('contract-search-rule?page=List', data, {
      headers: headers,
    });
  } else {
    result = axios.post('contract-search-rule', data, { headers: headers });
  }
  return result;
};
export const getContractsTableDataList = (pagination, page, app, filters) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getContractsTableListApi(pagination, page, app, filters)
      .then((response) => {
        if (pagination === 'latest') {
          sessionStorage.setItem(
            'rebateDashBoardContract',
            JSON.stringify(response.data.response),
          );
          dispatch(
            getContractsTableDataListDashBoardSuccess(response.data.response),
          );
        } else {
          dispatch(getContractsTableDataListSuccess(response.data.response));
        }
        dispatch(fetchEnd());
      })
      .catch((error) => {
        dispatch(fetchEnd());
        if (
          error.response &&
          error.response.data &&
          error.response.data.complete_error_message
        ) {
          toast.warn(error.response.data.complete_error_message);
        } else {
          //toast.error("Something went wrong.");
        }
      });
  };
};

const getContractsTableListApi = (tableLimit, page, app, filters) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (page === 'Designer Studio') {
    result = axios.get(
      'contract-list?is_update_approval_contract=false' +
      '&application_type=' +
      app,
      { headers: headers },
    );
  } else if (sessionStorage.getItem('application') === 'Collaboration Portal') {
    result = axios.get(
      'collaboration-contract?is_update_approval_contract=false',
      { headers: headers },
    );
  } else if (sessionStorage.getItem('application') === 'Audit Lab') {
    result = axios.get(
      'contract-list?is_update_approval_contract=false' +
      '&application_type=' +
      tableLimit,
      { headers: headers },
    );
  } else {
    if (tableLimit === 'AllApps') {
      result = axios.get('contract-list?is_update_approval_contract=false', {
        headers: headers,
      });
    } else if (tableLimit === 'Profit Optimization') {
      result = axios.get(
        'contract-list?is_update_approval_contract=false' +
        '&application_type=Customer Rebate',
        { params: filters, headers: headers },
      );
    } else if (tableLimit === 'latest') {
      result = axios.get(
        'contract-list?is_update_approval_contract=false' +
        '&application_type=' +
        sessionStorage.getItem('application') +
        '&latest=true',
        { params: filters, headers: headers },
      );
    } else if (app === 'IsUpdateApprovalContractFalse') {
      result = axios.get(
        'contract-list?application_type=' +
        sessionStorage.getItem('application'),
        { params: filters, headers: headers },
      );
    } else {
      result = axios.get(
        'contract-list?is_update_approval_contract=false' +
        '&application_type=' +
        sessionStorage.getItem('application'),
        { params: filters, headers: headers },
      );
    }
  }
  return result;
};

export const getContractsTableDataListSuccess = (data) => {
  return {
    type: CONTRACT_DATA_LIST,
    data,
  };
};
export const getContractsTableDataListDashBoardSuccess = (data) => {
  return {
    type: CONTRACT_DATA_LIST_DASHBOARD,
    data,
  };
};

//get updated contracts list
export const updatedContractsAllList = (data) => {
  return (dispatch) => {
    updatedContractsAllListAPI(data).then((response) => {
      dispatch(updatedContractsAllListSuccess(response.data.response));
    });
  };
};

export const updatedContractsAllListSuccess = (data) => {
  return {
    type: UPDATED_CONTRACT_ALL_LIST,
    data,
  };
};

const updatedContractsAllListAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contract-list?update_contract_id.in=' +
    data.toString() +
    '&application_type=' +
    sessionStorage.getItem('application') +
    '&update_request_closed=false&is_update_approval_contract=true',
    { headers: headers },
  );
  return result;
};
// Download contract search
export const downloadContractSearch = (data) => {
  return (dispatch) => {
    toast.info(
      'Downloading has been started in background. File will be downloaded automatically once ready.',
    );
    downloadContractSearchApi(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ContractRules' + '.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };
};

const downloadContractSearchApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('download-contract-search-rule', data, {
    headers: headers,
    responseType: 'blob',
  });
  return result;
};

//complete contract data starts here
export const downloadAllContracts = () => {
  toast.info(
    'Downloading has been started in background. File will be downloaded automatically once ready.',
  );
  return (dispatch) => {
    downloadAllContractsAPI()
      .then((response) => {
        dispatch(downloadSheet(response.data.response.records));
      })
      .catch((error) => {
        //toast.error("Something went wrong.");
      });
  };
};

const downloadAllContractsAPI = () => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get('contracts?limit=0', { headers: headers });
  return result;
};

//download sheet starts heare
export const downloadSheet = (data) => {
  return (dispatch) => {
    getDownloadSheetApi(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      var date = Date.now();
      link.setAttribute('download', date + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
};

const getDownloadSheetApi = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.post('generate-excel-sheet/contracts', data, {
    headers: headers,
    responseType: 'blob',
  });
  return result;
};

//financial postings history
export const fpHistory = (id, pagination, limit, type) => {
  return (dispatch) => {
    fpHistoryAPI(id, pagination, limit, type).then((response) => {
      dispatch(fpHistorySuccess(response.data.response));
      if (
        response &&
        response.data &&
        response.data.response &&
        response.data.response.records.length > 0
      ) {
        history.push('/contract-setup/fp-history/' + id);
      } else {
        toast.warn('No Records Found.');
      }
    });
  };
};

const fpHistoryAPI = (id, pagination, limit, type) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  const result = axios.get(
    'finpost-history/' +
    id +
    '?posting_type.in=' +
    type +
    '&page=' +
    pagination +
    '&limit=' +
    limit,
    { headers: headers },
  );
  return result;
};

export const fpHistorySuccess = (data) => {
  return {
    type: FP_HISTORY_SUCCESS,
    data,
  };
};

export const ediUpload = (file, type, id) => {
  return (dispatch) => {
    ediUploadAPI(file, type, id)
      .then((response) => {
        if (type === 'download') {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          var date = Date.now();
          link.setAttribute('download', date + '.txt'); //or any other extension
          document.body.appendChild(link);
          link.click();
          toast.success('File will be downloaded in the background.');
        } else {
          toast.success('File has been uploaded successfully');
        }
      })
      .catch((error) => {
        if (type === 'download') toast.error('File Download Failed.');
        else toast.error('File Uploading Error.');
      });
  };
};

const ediUploadAPI = (file, type, id) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  if (type === 'download') {
    var result = axios.get('contract-edi-download?id=' + id, {
      headers: headers,
    });
  } else {
    var result = axios.post('contract-edi-upload', file, { headers: headers });
  }
  return result;
};

export const massUpdateContract = (appType, data) => {
  return (dispatch) => {
    massUpdateContractAPI(appType, data)
      .then((response) => {
        dispatch(massUpdateContractSuccess(response.data.response));
        history.push('/mass-contract-update-result');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error(error.response.data.complete_error_message);
      });
  };
};

const massUpdateContractAPI = (appType, data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'contracts/mass-update/lookup?application_type=' + appType,
    data,
    { headers: headers },
  );
  return result;
};

export const massUpdateContractSuccess = (data) => {
  return {
    type: MASS_CONTRACT_UPDATE,
    data,
  };
};
export const massUpdateContractAction = (data) => {
  return (dispatch) => {
    massUpdateContractActionAPI(data)
      .then((response) => {
        dispatch(massUpdateContractActionSuccess(response.data.response));
        toast.success(response.data.messageLog[0].message_description);
        history.push('/contract-setup');
      })
      .catch((error) => {
        dispatch(fetchEnd());
        //toast.error("Something went wrong.");
      });
  };
};

const massUpdateContractActionAPI = (data) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post('contracts/mass-update/action', data, {
    headers: headers,
  });
  return result;
};

export const massUpdateContractActionSuccess = (data) => {
  return {
    type: MASS_CONTRACT_UPDATE_ACTION,
    data,
  };
};
export const uploadCalculationRules = (
  file,
  method,
  appType,
  SourceDataType,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    uploadCalculationRulesAPI(file, method, appType, SourceDataType)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(uploadCalculationRulesAPISuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};

const uploadCalculationRulesAPI = (file, method, appType, SourceDataType) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
    'Content-Type': 'multipart/form-data;',
  };
  var result = axios.post(
    '/calculation-rules/upload?calculation_method=' +
    method +
    '&application_type=' +
    appType +
    '&data_source_type=' +
    SourceDataType,
    file,
    { headers: headers },
  );
  return result;
};

export const uploadCalculationRulesAPISuccess = (data) => {
  return {
    type: UPLOAD_CALCULATION_RULES_SUCCESS,
    data,
  };
};

export const getApprovalLevelData = (functionality) => {
  return (dispatch) => {
    getApprovalLevelDataAPI(functionality)
      .then((response) => {
        dispatch(getApprovalLevelDataSuccess(response.data.response));
      })
      .catch((error) => { });
  };
};

const getApprovalLevelDataAPI = (functionality) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  if (functionality)
    result = axios.get(
      'approval-level?functionality=' +
      functionality +
      '&application_type=' +
      sessionStorage.getItem('application'),
      {
        headers: headers,
      },
    );
  else
    result = axios.get(
      'approval-level?functionality=contract&application_type=' +
      sessionStorage.getItem('application'),
      {
        headers: headers,
      },
    );
  return result;
};

export const getApprovalLevelDataSuccess = (data) => {
  return {
    type: APPROVAL_LEVEL_DATA,
    data,
  };
};
export const getExpiringContractData = (expiry) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getExpiringContractDataAPI(expiry)
      .then((response) => {
        dispatch(fetchEnd());
        dispatch(getExpiringContractDataSuccess(response.data.response));
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getExpiringContractDataAPI = (expiry) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.get(
    'contract-list?application_type=' +
    sessionStorage.getItem('application') +
    '&is_update_approval_contract=false&expiry=' +
    expiry,
    {
      headers: headers,
    },
  );
  return result;
};
export const getExpiringContractDataSuccess = (data) => {
  return {
    type: EXPIRY_DATA,
    data,
  };
};
export const getCalculationRulesValidateData = (
  data,
  type,
  calMethod,
  Toast,
) => {
  return (dispatch) => {
    dispatch(fetchStart());
    getCalculationRulesValidateDataAPI(data, type, calMethod)
      .then((response) => {
        if (Toast == 'Toast') {
          dispatch(fetchEnd());
          if (response.data.response && response.data.response.length > 0) {
            const messages = response.data.response;
            const toastContent =
              messages.length > 1 ? (
                <div>
                  {messages.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div>
              ) : (
                <div>{messages[0]}</div>
              );

            toast.warn(toastContent);
          }
        } else {
          dispatch(fetchEnd());
          dispatch(
            getCalculationRulesValidateDataSuccess(response.data.response),
          );
        }
      })
      .catch((error) => {
        dispatch(fetchEnd());
      });
  };
};
const getCalculationRulesValidateDataAPI = (data, type, calMethod) => {
  const headers = {
    Authorization: secureLocalStorage.getItem('aTk'),
  };
  var result;
  result = axios.post(
    'contract-rules/validate?rule_type=' +
    type +
    '&&calculation_method=' +
    calMethod,
    data,
    {
      headers: headers,
    },
  );
  return result;
};
export const getCalculationRulesValidateDataSuccess = (data) => {
  return {
    type: CALCU_RULE_VALID_MSG,
    data,
  };
};
